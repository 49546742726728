import { Calendar } from 'primereact/calendar';
import React, { useCallback, useEffect, useState } from 'react';
import { formatDate, toTitleCase, useDebounce } from '../../utils/helper';
import { deleteAxios, getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Constants from '../constants/Constants';

const CreateCategoryAdvertisment = ({ changeToast }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(null);
	const [portalPosition, setPortalposition] = useState();
	const [positionType, setPositionType] = useState({
		key: '',
		value: '',
	});
	const [orderid, setorderID] = useState();
	const [selectedParentCategory, setSelectedParentCategory] = useState([]);
	const [expired, setExpired] = useState({
		placementIds: [],
	});
	const [errors, setErrors] = useState({
		parentCategoryError: '',
		positionPortalError: '',
		orderIdError: '',
	});

	const navigate = useNavigate();
	const location = useLocation();
	const product_id = location.state;

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);

			const response = await getAxios(`${request.globalCategoryList}`, {
				params: {
					more_details: 1,
					product_category_id: product_id,
				},
			});

			if (response?.data?.apiresponse?.type === 'OK') {
				setData(response?.data?.record[0]);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [product_id]);

	const portalPositionMaster = useCallback(async () => {
		try {
			const portalResponse = await getAxios(
				`${request?.portalPositionMaster}?source=CATEGORY&join=parent_type`
			);
			const portalData = portalResponse?.data?.items.filter(
				(content) => content?.parent_type_id != null
			);
			setPortalposition(portalData);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		fetchData();
		portalPositionMaster();
	}, []);

	const loadOptions = useDebounce(async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}(${item?.product_category_id})`,
				value: item?.product_category_id,
			}));

			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	}, 1000);

	const portalPositionData = portalPosition?.map((item) => ({
		value: item.position_type_id,
		label: `${item.description} (${item.parent_type?.description})`,
		key: item?.parent_type?.position_type_id,
	}));

	const clearFormFields = () => {
		setPositionType({ key: '', value: '' });
		setorderID();
		setFromDate(new Date());
		setToDate(null);
		setSelectedParentCategory([]);
	};

	const productPlacement = useCallback(async () => {
		const isfromDate = formatDate(fromDate);
		const isToDate = toDate ? formatDate(toDate) : null;
		const body = {
			product_category_id: data?.product_category_id,
			position_type: positionType?.value,
			from_date: isfromDate,
			entry_type: 'CATEGORY',
			order_id: orderid,
			...(isToDate && { to_date: isToDate }),
		};

		try {
			const response = await postAxios(request.createProductPlacement, body);
			if (response?.data?.apiresponse?.type === 'OK') {
				changeToast(
					Constants.TOAST_SUCCESS,
					Constants.TOAST_SUCCESS_TITLE,
					response?.data?.apiresponse?.message
				);
			} else {
				changeToast(
					Constants.TOAST_ERROR,
					Constants.TOAST_ERROR_TITLE,
					response?.data?.apiresponse?.message
				);
			}
		} catch (error) {
			console.log(error);
		}
	}, [data, positionType, fromDate, toDate]);

	const deleteProductPlacement = useCallback(async () => {
		const ids = expired?.placementIds.join('&placement_id=');
		try {
			const response = await deleteAxios(
				`${request.deleteProductPlacement}?placement_id=${ids}`
			);
			if (response?.data?.apiresponse?.type === 'OK') {
				changeToast(
					Constants.TOAST_SUCCESS,
					Constants.TOAST_SUCCESS_TITLE,
					response?.data?.apiresponse?.message
				);

				setExpired({
					placementIds: [],
				});
			}
		} catch (error) {
			console.log(error);
		}
	}, [expired]);

	const handleUpdadeAdvertisement = useCallback(async () => {
		try {
			setErrors({ parentCategoryError: '', positionPortalError: '' });
			if (expired?.placementIds?.length > 0) {
				await deleteProductPlacement();
				fetchData();
				setErrors({ parentCategoryError: '', positionPortalError: '' });
			}

			if (!positionType?.value) {
				setErrors((prevErrors) => ({
					...prevErrors,
					positionPortalError: 'Portal position is required.',
				}));
			}
			if (
				positionType.key === 'CAT_PAGE' &&
				selectedParentCategory.length === 0
			) {
				setErrors((prevErrors) => ({
					...prevErrors,
					parentCategoryError: 'This field is required.',
				}));
				return;
			}

			if (positionType.value) {
				await productPlacement();
				clearFormFields();
				fetchData();
			}
		} catch (error) {
			console.log(error);
		}
	}, [positionType, fromDate, toDate, expired, selectedParentCategory]);

	const globalCategoryDetails = [
		{
			label: 'CATEGORY NAME',
			name: data?.category_name,
			ID: data?.product_category_id,
		},

		{
			label: 'PARENT CATEGORY NAME',
			name: data?.parent_category_name,
			ID: data?.primary_parent_category_id,
		},

		{
			label: 'DESCRIPTION',
			name: data?.description,
		},
	];
	const renderAction = (rowData) => {
		return (
			<div className=" d-flex flex-column justify-content-center pl-3">
				<input
					className="form-check-input"
					type="checkbox"
					onChange={(e) => {
						if (e.target.checked) {
							setExpired((prevState) => ({
								placementIds: [
									...prevState.placementIds,
									rowData?.placement_id,
								],
							}));
						} else {
							setExpired((prevState) => ({
								placementIds: prevState.placementIds.filter(
									(id) => id !== rowData?.placement_id
								),
							}));
						}
					}}
				/>
			</div>
		);
	};

	const secondaryBanner = data?.category_contents?.filter(
		(item) => item?.content_type_id === 'SECONDARY_ICON'
	);


	return (
		<div className="w-100 d-flex flex-row gap-5">
			<div className="update-category-details-container  d-flex flex-column gap-0 p-4">
				<>
					{loading && <Loader />}
					{globalCategoryDetails &&
						globalCategoryDetails.map((detail, index) => (
							<div
								key={index}
								className="update-category-detail d-flex flex-column"
							>
								<span>{detail.label}</span>
								{detail.label === 'DESCRIPTION' ? (
									<span className="fw-normal">{detail.name}</span>
								) : (
									<span className="">{detail.name}</span>
								)}
								{detail.ID && (
									<span className="">
										<strong>ID:</strong> {detail.ID}
									</span>
								)}
							</div>
						))}

					<span className="icon-class">SECONDARY ICON : </span>
					<div className="mb-2  gap-2 banner-category ">
						<div className="">
							<div className="position-relative banner-category-tileBanner">
								<img
									src={
										secondaryBanner?.[0]?.object_info?.small?.public_url ||
										'/images/tile-no-image.webp'
									}
									alt={`Banner Preview`}
									style={{ width: '100%' }}
									className=" p-1 rounded"
								/>
							</div>
						</div>
					</div>
				</>
			</div>

			<div className=" card update-category-form d-flex align-items-center align-self-start">
				<div className=" w-100 d-flex justify-content-center pt-0  p-4 m-auto">
					<div className="card-body p-0">
						{data?.product_placement?.length > 0 && (
							<>
								<div className="fw-bold mb-2">Existing Records</div>
								<div className="mb-3">
									<DataTable value={data?.product_placement} showGridlines>
										<Column field="placement_id" header="ADS" />
										<Column
											field="position_description"
											header="ADVERTISEMENT"
										/>
										<Column field="from_date" header="From Date" />
										<Column field="to_date" header="To Date" />

										{data?.product_placement?.some(
											(item) => item.display_category_name
										) && (
											<Column
												field="display_category_name"
												header="Display in Category"
											/>
										)}

										<Column body={renderAction} header="Expire" />
									</DataTable>{' '}
								</div>
							</>
						)}

						<div className="fw-bold mb-2">Add New Advertisment Option</div>
						<div className="d-flex gap-2 w-100">
							<div className="form-group mb-3 w-50">
								<label htmlFor="" className="form-label form-required">
									Portal Position
								</label>
								<Select
									options={portalPositionData}
									className="basic-multi-select"
									classNamePrefix="select"
									placeholder="Select position"
									required
									onChange={(e) =>
										setPositionType({
											key: e.key,
											value: e.value,
										})
									}
								/>
								{errors?.positionPortalError && (
									<span style={{ color: 'red', fontSize: '12px' }}>
										{errors.positionPortalError}
									</span>
								)}
							</div>

							<div className="form-group mb-3 w-50">
								<label
									htmlFor=""
									className={`form-label ${positionType?.key === 'CAT_PAGE' ? 'form-required' : ''}`}
								>
									Show in Category
								</label>
								<AsyncSelect
									cacheOptions
									loadOptions={loadOptions}
									placeholder={'Search'}
									defaultOptions
									isDisabled={positionType?.key === 'HOME_PAGE'}
									onChange={setSelectedParentCategory}
									value={selectedParentCategory}
								/>
								{errors.parentCategoryError && (
									<span style={{ color: 'red', fontSize: '12px' }}>
										{errors.parentCategoryError}
									</span>
								)}
							</div>
						</div>
						<div className="form-group d-flex gap-2 mb-3">
							<div className="d-flex flex-column">
								<label htmlFor="" className="form-label">
									From
								</label>
								<Calendar
									value={fromDate}
									onChange={(e) => setFromDate(e.value)}
									className="p-datepicker-today"
								/>
							</div>
							<div className="d-flex flex-column">
								<label htmlFor="" className="form-label">
									To
								</label>

								<Calendar
									value={toDate}
									onChange={(e) => setToDate(e.value)}
									placeholder="Select to date"
								/>
							</div>
						</div>

						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Order Id
							</label>

							<input
								type="text"
								className="form-control"
								placeholder="Order Id"
								onChange={(e) => {
									setorderID(e.target.value);
								}}
								value={orderid}
							/>
							{errors.orderIdError && (
								<span style={{ color: 'red', fontSize: '12px' }}>
									{errors.orderIdError}
								</span>
							)}
						</div>

						<div className="d-flex prime-react-btn w-100 align-items-center gap-2 ">
							<button
								className="btn w-100 text-white"
								style={{ backgroundColor: 'grey' }}
								onClick={() => {
									navigate('/global-product-list');
								}}
							>
								Go Back
							</button>
							<button
								type="submit"
								className="btn submit-btn w-100 text-white"
								onClick={handleUpdadeAdvertisement}
							>
								{loading ? 'Updating...' : 'Update'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateCategoryAdvertisment;
