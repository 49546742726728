export const postingStatusData = [
	{
		name: 'APPROVED',
		value: 'APPROVED',
	},
	{
		name: 'PENDING',
		value: 'PENDING',
	},
	{
		name: 'REJECTED',
		value: 'REJECTED',
	},
	{
		name: 'DEVELOPMENT',
		value: 'DEVELOPMENT',
	},
	{
		name: 'ALL',
		value: 'ALL',
	},
];

export const postingTypeData = [
	{ name: 'PICTURE', value: 'PICTURE' },
	{ name: 'SOCIAL', value: 'SOCIAL' },
	{ name: 'VIDEO', value: 'VIDEO' },
	{ name: 'BUY', value: 'BUY' },
	{ name: 'SELL', value: 'SELL' },
	{ name: 'JOB', value: 'JOB' },
	{ name: 'HOMEPAGE_BANNER', value: 'HOMEPAGE_BANNER' },
];
