import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import AsyncSelect from 'react-select/async';
import { Button } from 'primereact/button';
import Loader from '../loader/Loader';

import { RxCross1 } from 'react-icons/rx';
import Constants from '../constants/Constants';

const MappedScreen = ({ changeToast }) => {
	const location = useLocation();
	const data = location.state;
	const [loading, setLoading] = useState(false);
	const [menuHtml, setMenuHtml] = useState('');
	const [items, setItems] = useState([]);
	const [category, setCategory] = useState('');
	const [selectedOption, setSelectedOption] = useState([]);
	const menuRef = useRef(null);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(
				`${request.getSupplierList}?website_id=${data?.website_id}`
			);
			setLoading(false);
			if (response?.data?.apiresponse?.type === 'OK') {
				setMenuHtml(response?.data?.menu_html);
				setItems(response?.data?.items);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [data]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (menuRef.current) {
			const listItems = menuRef.current.querySelectorAll('li.menu_leaf');
			const itemsWithMappedCountOne = items?.filter(
				(item) => item.mapped_count >= 1
			);

			listItems.forEach((li) => {
				const anchor = li.querySelector('a');

				if (anchor) {
					anchor.addEventListener('click', (event) => {
						event.preventDefault();
					});

					const matchingItem = itemsWithMappedCountOne.find(
						(item) => item.product_category_id === li.id
					);

					if (matchingItem) {
						li.classList.add('mapped');

						let countText = anchor.querySelector('.mapped-count');
						if (!countText) {
							countText = document.createElement('span');
							countText.className = 'mapped-count';
							countText.style.marginLeft = '5px';
							anchor.appendChild(countText);
						}
						if (matchingItem.mapped_count >= 1) {
							countText.textContent = ` (${matchingItem.mapped_count})`;
						} else {
							countText.textContent = '';
						}
					} else {
						li.classList.remove('mapped');
						const countText = anchor.querySelector('.mapped-count');
						if (countText) {
							countText.textContent = '';
						}
					}
					const existingIcon = anchor.querySelector('.fa-edit');
					if (!existingIcon) {
						const icon = document.createElement('i');
						icon.className = 'fa fa-edit';
						icon.style.cursor = 'pointer';
						icon.style.marginLeft = '10px';

						icon.addEventListener('click', () => {
							handleIconClick(li.id);
						});

						anchor.appendChild(icon);
					}
				}
			});
		}
	}, [menuHtml, items]);

	const handleIconClick = (productId) => {
		const data = items.find((id) => id.product_category_id === productId);
		setCategory(data);

		if (data && data.mapped_categories && data.mapped_categories.length > 0) {
			const defaultMappedCategory = data.mapped_categories.map((mappedCat) => ({
				label: mappedCat.portal_category_name,
				value: mappedCat.portal_category_id,
			}));
			setSelectedOption(defaultMappedCategory);
		} else {
			setSelectedOption(null);
		}
	};

	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}(${item?.product_category_id})`,
				value: item?.product_category_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	};

	const handleMapCategory = useCallback(async (item1, item2) => {
		try {
			const body = {
				role_type_id_to: 'PORTAL_CATEGORY',
				category_id_from: item1,
				role_type_id_from: 'WEBSITE_CATEGORY',
				category_id_to: item2,
				website_id: data?.website_id,
			};
			const response = await postAxios(request.categoryMapping, body);
			if (response?.data?.apiresponse?.type === 'OK') {
				changeToast(
					Constants.TOAST_SUCCESS,
					Constants.TOAST_SUCCESS_TITLE,
					response?.data?.apiresponse?.message
				);
				setMenuHtml(null);
				fetchData();
			} else if (response?.data?.apiresponse?.type === 'ERROR') {
				changeToast(
					Constants.TOAST_ERROR,
					Constants.TOAST_ERROR_TITLE,
					response?.data?.apiresponse?.message
				);
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	const handleCloseCategory = useCallback(() => {
		setCategory(null);
	}, []);
	const url =
		data?.website_host.startsWith('http://') ||
		data?.website_host.startsWith('https://')
			? data?.website_host
			: `http://${data?.website_host}`;
	return (
		<>
			<div className="mapped-screen-details">
				<div className="d-flex gap-5">
					<div className="mapped-screen-data">
						<div className="mapped-screen-title">Website Name</div>
						<div className='d-flex align-items-center gap-1'>
							<div className="mapped-screen-name">{data?.website_name} </div>
							<div className='mapped-screen-tipdata'>({data?.section})</div>
						</div>
					</div>
					<div className="mapped-screen-data">
						<div className="mapped-screen-title">Website Path</div>
						<div className="mapped-screen-name">{data?.webapp_path}</div>
					</div>

					<div className="mapped-screen-data">
						<div className="mapped-screen-title">URL </div>
						<a href={url} target="_blank" rel="noopener noreferrer">
							{data?.website_host}
						</a>
					</div>
				</div>
			</div>
			<div className="mapped-screen-container d-flex gap-2  flex-row">
				{loading && <Loader />}
				<div className="w-50">
					{loading ? (
						<div className="w-100 ">
							<div className="w-50 h-100" />
						</div>
					) : items?.length ? (
						<div className="w-100 menu-html-container">
							<div
								className="w-50 h-100"
								ref={menuRef}
								dangerouslySetInnerHTML={{ __html: menuHtml }}
							/>
						</div>
					) : (
						<div className="w-100">
							<div className="w-50 fw-bold h-100" ref={menuRef} />
							No Data Found
						</div>
					)}
				</div>
				<div className=" w-75">
					{category && (
						<div className="category-container d-flex  flex-column w-100 gap-3">
							<div onClick={handleCloseCategory} className="category-close-btn">
								<RxCross1 />
							</div>
							<div className="category-id-from gap-2 px-3">
								<sapn className="fw-bold ">
									Choose Portal Category for Mapping
								</sapn>
								<div className="d-flex flex-column w-100">
									<sapn>Category Name : {category?.category_name}</sapn>
									<span>Category Id : {category?.product_category_id}</span>
								</div>
							</div>
							<div className="category-select px-3">
								<AsyncSelect
									cacheOptions
									loadOptions={loadOptions}
									isMulti
									defaultOptions
									onChange={setSelectedOption}
									value={selectedOption}
								/>
							</div>
							<div className="mapping-btn">
								<Button
									className=" p-button-success mr-2 w-25 d-flex justify-content-center "
									onClick={() => {
										const selectedValues = selectedOption?.map(
											(option) => option.value
										);
										handleMapCategory(
											category?.product_category_id,
											selectedValues
										);
									}}
								>
									Map Categories
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MappedScreen;
