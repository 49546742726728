import React from 'react';
import { Button } from 'primereact/button';
const UpdateProductData = ({ data }) => {
	const productDetails = [
		{
			label: 'PRODUCT NAME',
			name: data?.product_name,
			ID: data?.product_id,
		},

		{
			label: 'DESCRIPTION',
			name: data?.product_description,
			// ID: data?.primary_parent_category_id,
		},

		{
			label: 'PRODUCT PRICE',
			name: `${data?.product_price} ${data?.product_price_currency}`,
		},
		{
			label: 'DISCOUNTED PRICE',
			name: `${data?.discounted_price}`,
		},
	];
	return (
		<div className="update-category-details-container  d-flex flex-column gap-0 p-4">
			<>
				{/* {loading && <Loader />} */}
				{productDetails.map((detail, index) => (
					<div
						key={index}
						className="update-category-detail d-flex flex-column"
					>
						<span>{detail.label}</span>
						{detail.label === 'DESCRIPTION' ? (
							<span className="fw-normal">{detail.name}</span>
						) : (
							<span className="">{detail.name}</span>
						)}
						{detail.ID && (
							<span className="">
								<strong>ID:</strong> {detail.ID}
							</span>
						)}
					</div>
				))}
				<div className="d-flex gap-4">
					<div>
						<span className="icon-class">PRODUCT IMAGE: </span>
						<div className="  category-preview ">
							{data?.detail_image_url && (
								<div className="d-flex flex-column">
									<div className="mt-2 position-relative category-preview-container">
										<img
											src={data?.detail_image_url || '/images/no-icon.webp'}
											alt="Category Icon Preview"
											style={{ width: '100px', height: '100px' }}
											className="p-1 rounded"
										/>

										{data?.detail_image_url != '/images/no-icon.webp' && (
											<Button
												type="button"
												className="position-absolute category-preview-iconBtn"
												icon="pi pi-times"
												aria-label="Cancel"
												rounded
												severity="danger"
												text
												raised
											/>
										)}
									</div>
								</div>
							)}
						</div>
					</div>

					{/* <div>
						<span className="icon-class">SECONDARY ICON: </span>
						<div className="  category-preview ">
							{secondaryIcon && (
								<div className="d-flex flex-column">
									<div className="mt-2 position-relative category-preview-container">
										<img
											src={
												(getValidArray(secondaryIcon?.existingIcon) &&
													secondaryIcon?.existingIcon[0]?.object_info?.small
														?.public_url) ||
												(secondaryIcon?.newIcon &&
													URL.createObjectURL(secondaryIcon?.newIcon[0])) ||
												secondaryIcon?.blankIcon ||
												'/images/no-icon.webp'
											}
											alt="Category Icon Preview"
											style={{ width: '100px', height: '100px' }}
											className="p-1 rounded"
										/>

										{getValidArray(secondaryIcon?.existingIcon) ||
										secondaryIcon?.newIcon !== null ? (
											<Button
												type="button"
												className="position-absolute category-preview-iconBtn"
												onClick={() => {
													if (getValidArray(secondaryIcon?.newIcon)) {
														handleDeleteBanner(null, 'secondary_icon');
													} else if (
														secondaryIcon?.existingIcon !== null &&
														secondaryIcon?.existingIcon.length > 0
													) {
														handleDeleteBanner(
															null,
															'icon',
															secondaryIcon?.existingIcon[0]?.content_id
														);
													}
												}}
												icon="pi pi-times"
												aria-label="Cancel"
												rounded
												severity="danger"
												text
												raised
											/>
										) : null}
									</div>
								</div>
							)}
						</div>
					</div> */}
				</div>

				{/* <span className="icon-class">HERO BANNER : </span>
				<div className="mb-2  gap-2 banner-category ">
					{existingBanners?.map((fileURL, index) => (
						<div className="">
							<div
								key={index}
								className="position-relative banner-category-container "
							>
								<img
									src={fileURL?.object_info?.small?.public_url}
									alt={`Banner Preview ${index}`}
									style={{ width: '100%' }}
									className=" p-1 rounded"
								/>

								<Button
									className="position-absolute category-preview-bannerBtn"
									type="button"
									icon="pi pi-times"
									rounded
									text
									severity="danger"
									onClick={() =>
										handleDeleteBanner(index, 'banner', fileURL?.content_id)
									}
									aria-label="Cancel"
								/>
							</div>
						</div>
					))}
					{newBanners?.length > 0
						? newBanners.map((fileURL, index) => (
								<div
									key={index}
									className="position-relative banner-category-container "
								>
									<img
										src={fileURL?.preview}
										alt={`Banner Preview ${index}`}
										style={{ width: '100%' }}
										className="p-1 rounded"
									/>
									{fileURL?.preview && (
										<Button
											className="position-absolute category-preview-bannerBtn "
											type="button"
											icon="pi pi-times"
											rounded
											text
											severity="danger"
											onClick={() => handleDeleteBanner(index, 'new')}
											aria-label="Cancel"
										/>
									)}
								</div>
							))
						: blankBanner.map((image, index) => (
								<div
									key={index}
									className="position-relative banner-category-container "
								>
									<img
										src={image}
										alt={`Blank Banner ${index}`}
										style={{ width: '100%' }}
										className="p-1 rounded"
									/>
								</div>
							))}
				</div>  */}
			</>
		</div>
	);
};

export default UpdateProductData;
