import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';

import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';

import { MdOutlineResetTv } from 'react-icons/md';
import { Tooltip } from 'primereact/tooltip';

import { TieredMenu } from 'primereact/tieredmenu';
import { getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import ImageGallery from 'react-image-gallery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../loader/Loader';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';

import { Paginator } from 'primereact/paginator';

import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Constants from '../constants/Constants';
import {
	postingStatusData,
	postingTypeData,
} from '../../utils/defaultConstants';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

const AppPostings = ({ changeToast }) => {
	const [productsList, setProductsList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [deleteProductDialog, setDeleteProductDialog] = useState(false);
	const [currentImageShow, setCurrentImageShow] = useState(false);
	const [currentImageData, setCurrentImageData] = useState([]);
	const [productToDelete, setProductToDelete] = useState(null);
	const [postingType, setPostingType] = useState();
	const [postingStatus, setPostingStatus] = useState();
	const [updatepostDialog, setUpdatePostDialog] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(50);

	const [currentPage, setCurrentPage] = useState(1);
	const [updatePost, setUpdatePost] = useState({
		id: '',
		contentType: '',
		mediaObject: [],
		title: '',
		message: '',
		cta: '',
		text1: '',
		text2: '',
		text3: '',
		postingType: '',
		postingStatus: '',
	});

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const data = {
				rows: rows,
				offset: (currentPage - 1) * rows,
				show_all: 1,
				page: currentPage,
				posting_type: postingType,
				posting_status: postingStatus,
				show_all_status: 1,
			};
			const res = await postAxios(request.getPost, data);

			if (res?.data?.apiresponse?.type === 'OK') {
				setProductsList(res?.data);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [postingType, postingStatus, currentPage, rows]);

	useEffect(() => {
		fetchData();
	}, [first, currentPage, rows, fetchData]);

	const deleteProduct = async (product_id) => {
		const data = {
			id: product_id,
		};
		const res = await postAxios(request.deletePost, data);
		if (res.data.apiresponse.type == 'OK') {
			const updatedRecords = productsList?.records.filter(
				(val) => val.id !== product_id
			);
			setProductsList((prev) => ({
				...prev,
				records: updatedRecords,
				metadata: {
					...prev.metadata,
					total_post: prev.metadata.total_post - 1,
				},
			}));
		} else {
			changeToast(
				Constants.TOAST_ERROR,
				Constants.TOAST_ERROR_TITLE,
				res?.data?.apiresponse?.message
			);
		}
	};

	const handleSearch = useCallback(() => {
		fetchData();
	}, [postingStatus, postingType]);

	const handleResetFilter = useCallback(() => {
		setPostingStatus('');
		setPostingType('');
		setCurrentPage(1);
		setFirst(0);
		fetchData();
	}, []);

	const onPageChange = (event) => {
		setFirst(event.first);
		setCurrentPage(event.page + 1);
		setRows(event.rows);
	};
	const editPost = (rowData) => {
		const imageGalleryArray = [];
		for (var i = 0; i < rowData.mediaobjects?.length; i++) {
			var data = {
				thumbnail: rowData.mediaobjects[i].thumb_url,
			};
			imageGalleryArray.push(data);
		}

		setUpdatePost({
			id: rowData?.id,
			contentType: rowData?.mediaobjects?.[0]?.mime_type,
			mediaObject: imageGalleryArray,
			title: rowData?.title,
			message: rowData?.message,
			cta: rowData?.cta,
			text1: rowData?.text1,
			text2: rowData?.text2,
			text3: rowData?.text3,
			postingType: rowData?.posting_type,
			postingStatus: rowData?.posting_status,
		});
		setUpdatePostDialog(true);
	};

	const handleUpdatePost = useCallback(async () => {
		setSubmitted(true);
		const data = {
			cta: updatePost?.cta,
			id: updatePost?.id,
			message: updatePost?.message,
			posting_status: updatePost?.postingStatus,
			posting_type: updatePost?.postingType,
			text1: updatePost?.text1,
			text2: updatePost?.text2,
			text3: updatePost?.text3,
			title: updatePost?.title,
		};
		const res = await postAxios(request.updatePost, data);
		if (res?.data?.apiresponse?.type == 'OK') {
			setSubmitted(false);
			changeToast(
				Constants.TOAST_SUCCESS,
				Constants.TOAST_SUCCESS_TITLE,
				res?.data?.apiresponse?.message
			);
			fetchData();
			setUpdatePostDialog(false);
		} else {
			setSubmitted(false);
			changeToast(
				Constants.TOAST_ERROR,
				Constants.TOAST_ERROR_TITLE,
				res?.data?.apiresponse?.message
			);
			setUpdatePostDialog(false);
		}
	}, [updatePost]);

	const renderCount = (rowData, { rowIndex }) => {
		const serialNumber = first + rowIndex + 1;
		return (
			<div className="flex justify-content-evenly gap-1">{serialNumber}</div>
		);
	};

	const renderMessage = (rowData) => {
		const [expanded, setExpanded] = useState(false);

		const toggleExpanded = () => {
			setExpanded(!expanded);
		};

		const messagePreview =
			rowData?.message?.length > 160
				? `${rowData.message.substring(0, 160)}... `
				: rowData.message;
		return (
			<div className="webiste-detail-container">
				<div className="category-details-id gap-1">
					<div>
						<strong>Title: </strong> {rowData?.title || 'No Data'}
					</div>

					<div>
						<strong>Description: </strong>
						{expanded ? (
							rowData?.message
						) : (
							<>
								{messagePreview}
								{rowData?.message?.length > 100 && (
									<>
										<span
											className="cursor-pointer fw-semibold show-more"
											onClick={toggleExpanded}
										>
											Show More
										</span>
									</>
								)}
							</>
						)}
						{expanded && rowData?.message?.length > 100 && (
							<span
								className="cursor-pointer fw-semibold show-more"
								onClick={toggleExpanded}
							>
								{' '}
								Show Less
							</span>
						)}
					</div>
					<div>
						<div>
							<strong>Text1:</strong> {rowData?.text1 || 'No Data'}
						</div>
						<div>
							<strong>Text2:</strong>: {rowData?.text2 || 'No Data'}
						</div>
						<div>
							<strong>Text3:</strong> {rowData?.text3 || 'No Data'}
						</div>
						<div>
							<strong>CTA:</strong>{' '}
							<a href={rowData?.cta} target="_blank" rel="noopener noreferrer">
								{rowData?.cta || 'No Data'}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderMediaObject = (rowData) => {
		return (
			<div>
				<div>
					<span className="font-bold ">Posting By:</span>
					<span className="">
						{' '}
						{rowData?.posting_by_name} ({rowData?.posting_by})
					</span>
				</div>
				<div>
					<span className="font-bold ">Posting ID:</span>
					<span className=""> {rowData?.id}</span>
				</div>
				<div>
					<span className="font-bold ">No Of Media:</span>
					<span className=""> {rowData?.mediaobjects?.length}</span>
				</div>
				<div>
					<span className="font-bold ">FileName:</span>
					<span> {rowData?.mediaobjects?.[0]?.filename}</span>
				</div>
				<div>
					<span className="font-bold">Mime Type:</span>
					<span> {rowData?.mediaobjects?.[0]?.mime_type}</span>
				</div>
			</div>
		);
	};

	const renderIcon = (rowData) => {
		const currentImage = () => {
			const imageGalleryArray = [];
			for (var i = 0; i < rowData.mediaobjects?.length; i++) {
				var data = {
					original: rowData.mediaobjects[i].url,
					thumbnail: rowData.mediaobjects[i].thumb_url,
				};
				imageGalleryArray.push(data);
			}
			setCurrentImageShow(true);
			setCurrentImageData(imageGalleryArray);
		};
		return (
			<div className="w-100 h-100 cursor-pointer" onClick={currentImage}>
				<div className="d-flex align-items-center justify-content-center">
					<img
						src={rowData?.mediaobjects?.[0]?.url}
						alt={rowData?.mediaobjects?.[0]?.fileName}
						className="product-image"
						style={{ width: '100px' }}
					/>
				</div>
			</div>
		);
	};
	const renderActions = (rowData) => {
		const menu = useRef(null);
		const items = [
			{
				label: 'Update',
				icon: 'pi pi-file-edit',
				command: () => {
					editPost(rowData);
				},
			},

			// {
			// 	label: 'Delete',
			// 	icon: 'pi pi-trash',
			// 	command: () => {
			// 		setDeleteProductDialog(true);
			// 		setProductToDelete(rowData?.id);
			// 	},
			// },
		];
		return (
			<div className=" cursor-pointer flex justify-content-center">
				<TieredMenu model={items} popup ref={menu} breakpoint="767px" />
				<span
					className="global-category-action"
					icon="pi pi-check"
					aria-label="Filter"
					label="Show"
					onClick={(e) => menu.current.toggle(e)}
				>
					<PiDotsThreeOutlineVerticalBold />
				</span>
			</div>
		);
	};
	const accept = () => {
		if (productToDelete) {
			deleteProduct(productToDelete);
		}
	};

	const reject = () => {
		setDeleteProductDialog(false);
	};

	const footerContent = (
		<React.Fragment>
			<Button
				variant="outline-danger"
				onClick={() => setUpdatePostDialog(false)}
				className="md:w-6rem md:h-3rem"
			>
				Cancel
			</Button>
			<Button
				variant="outline-success"
				icon="pi pi-check"
				className="md:w-6rem md:h-3rem"
				onClick={handleUpdatePost}
				autoFocus
			>
				{submitted ? 'Updating...' : 'Update'}
			</Button>
		</React.Fragment>
	);
	return (
		<div>
			{loading && <Loader />}

			<div className="mb-2 d-flex gap-2  justify-content-between   w-100">
				<div className="d-flex flex-column  gap-2">
					<span className="h2">Post List</span>
					<div className="pr-3">
						<span className="font-bold ">Total Records:</span>
						<span> {productsList?.metadata?.total_post} </span>
					</div>
				</div>
				<div
					style={{ width: '75%' }}
					className="d-flex gap-2 flex-row  justify-content-end align-items-center "
				>
					<div className="d-flex mt-1 gap-2 cursor-pointer">
						<div className="field ">
							<label>Posting Type</label>
							<Dropdown
								value={postingType}
								onChange={(e) => setPostingType(e.value)}
								options={postingTypeData}
								optionLabel="name"
								placeholder="Posting Type"
								className="w-full md:w-14rem md:h-3rem"
							/>
						</div>
					</div>

					<div className="d-flex gap-2  mt-1 cursor-pointer">
						<div className="field ">
							<label>Posting Status</label>
							<Dropdown
								value={postingStatus}
								onChange={(e) => setPostingStatus(e.value)}
								options={postingStatusData}
								optionLabel="name"
								placeholder="Posting Status"
								className="w-full md:w-14rem md:h-3rem"
							/>
						</div>
					</div>
					<div className="d-flex gap-2 mt-3">
						<span className="p-input-icon-left d-flex gap-2">
							<Button
								variant="outline-success"
								disabled={loading}
								onClick={handleSearch}
								className="md:w-10rem md:h-3rem"
							>
								Search
							</Button>
						</span>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<Button
							variant=""
							disabled={loading}
							onClick={!loading ? fetchData : null}
							className="md:w-8rem d-flex gap-1 align-items-center justify-content-center refresh-btn"
						>
							<IoMdRefresh size="1.3em" />
							Refresh
						</Button>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<span data-pr-tooltip="Reset" data-pr-position="top">
							<MdOutlineResetTv
								style={{
									stroke: '#fff',
									fill: 'green',
									width: '50px',
									height: '50px',
								}}
								onClick={handleResetFilter}
								size="1.3em"
							/>
						</span>
						<Tooltip target="[data-pr-tooltip]" />
					</div>
				</div>
			</div>
			<ConfirmDialog
				visible={deleteProductDialog}
				onHide={() => setDeleteProductDialog(false)}
				message="Are you sure you want to delete this post ?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={accept}
				reject={reject}
			/>
			<DataTable value={productsList?.records} showGridlines>
				<Column body={renderCount} header="S.No" />
				<Column body={renderIcon} header="Images/Videos" />
				<Column body={renderMediaObject} header="Media Object" />
				<Column body={renderMessage} header="Posting Content" />
				<Column field="posting_type" header="Posting Type" />
				<Column field="posting_status" header="Posting Status" />

				<Column body={renderActions} header="Action" />
			</DataTable>

			<Dialog
				visible={updatepostDialog}
				style={{ width: '80%' }}
				header="Update Post Details"
				modal
				className="p-fluid"
				footer={footerContent}
				onHide={() => {
					if (!updatepostDialog) return;

					setUpdatePostDialog(false);
				}}
				draggable={false}
				resizable={false}
			>
				<div className="row">
					<div className="col-6">
						<div className="field">
							<label htmlFor="postingType">Posting Type</label>
							<Dropdown
								value={updatePost?.postingType}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, postingType: e.value }))
								}
								options={postingTypeData}
								optionLabel="name"
								placeholder="Select a Status"
								className="w-full md:w-100"
							/>
						</div>
						<div className="field">
							<label htmlFor="description">Posting Status</label>
							<Dropdown
								value={updatePost?.postingStatus}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, postingStatus: e.value }))
								}
								options={postingStatusData}
								optionLabel="name"
								placeholder="Select a Status"
								className="w-full md:w-100 text-uppercase"
							/>
						</div>
						<div className="field">
							<label htmlFor="image">Images/Videos</label>
							<InputText
								id="image"
								value={updatePost?.contentType}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, contentType: e.value }))
								}
								required
								autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
							/>
						</div>
						<div className="field">
							<label htmlFor="mediaobject">Media Objects</label>
							<div className="d-flex gap-2">
								{updatePost?.mediaObject?.map((data, index) => {
									return (
										<div key={index} className="">
											<img src={data.thumbnail} height={100} width={100} />
										</div>
									);
								})}
							</div>
						</div>
						<div className="field">
							<label htmlFor="cta">CTA</label>
							<InputText
								id="cta"
								value={updatePost?.cta}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, cta: e.target.value }))
								}
								// required
								// autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
							/>
						</div>
					</div>
					<div className="col-6">
						<div className="field">
							<label htmlFor="title">Title</label>
							<InputText
								id="title"
								value={updatePost?.title}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, title: e.target.value }))
								}
								// required
								// autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
							/>
						</div>

						<div className="field">
							<label htmlFor="message">Description</label>
							<InputTextarea
								id="message"
								value={updatePost?.message}
								onChange={(e) =>
									setUpdatePost((prev) => ({
										...prev,
										message: e.target.value,
									}))
								}
								required
								rows={2}
							/>
						</div>

						<div className="field">
							<label htmlFor="text1">Text1</label>
							<InputTextarea
								id="text1"
								value={updatePost?.text1}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, text1: e.target.value }))
								}
								// required
								// autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
								rows={2}
							/>
						</div>
						<div className="field">
							<label htmlFor="text2">Text2</label>
							<InputTextarea
								id="text2"
								value={updatePost?.text2}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, text2: e.target.value }))
								}
								// required
								// autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
								rows={2}
							/>
						</div>

						<div className="field">
							<label htmlFor="text3">Text3</label>
							<InputTextarea
								id="text3"
								value={updatePost?.text3}
								onChange={(e) =>
									setUpdatePost((prev) => ({ ...prev, text3: e.target.value }))
								}
								// required
								// autoFocus
								// className={classNames({
								// 	'p-invalid': submitted && !product.name,
								// })}
								rows={2}
							/>
						</div>
					</div>
				</div>
			</Dialog>

			<div className="image-gallery">
				{currentImageShow && (
					<Dialog
						visible={currentImageShow}
						style={{ width: '80%', height: '85%', maxHeight: '100%' }}
						header="Post Images/Videos"
						modal
						className="p-fluid"
						onHide={() => {
							if (!currentImageShow) return;
							setCurrentImageShow(false);
							currentImageData([]);
						}}
					>
						<ImageGallery
							originalHeight="100vh"
							originalWidth="100vw"
							fullscreen={true}
							items={currentImageData}
						/>
					</Dialog>
				)}
			</div>

			<Paginator
				first={first}
				rows={rows}
				totalRecords={productsList?.metadata?.total_post}
				rowsPerPageOptions={[50, 100, 150, 200, 250]}
				onPageChange={onPageChange}
			/>
		</div>
	);
};

export default AppPostings;
