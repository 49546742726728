import axios from 'axios'
axios.defaults.withCredentials = true
const getAxios=async (url,data)=>{
    const res=await axios.get(url,data)
    return res
}
const postAxios=async (url,data)=>{
    const res=await axios.post(url,data)
    return res;
}
const deleteAxios =async (url,data)=>{
  const res = await axios.delete(url,data)
  return res;
}
export {getAxios,postAxios,deleteAxios}