import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import Cropper from 'react-easy-crop';

const ImageCropper = ({
	setCrop,
	crop,
	src,
	setCompletedCrop,
	zoom,
	setZoom,
	bannerType,
	generateCroppedImage,
	handleCloseCropper,
}) => {
    
	const onCropChange = (newCrop) => {
		if (newCrop) {
			setCrop(newCrop);
		}
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCompletedCrop(croppedAreaPixels);
	};
	return (
		<>
			<Dialog
				visible={!!src}
				onHide={handleCloseCropper}
				style={{ width: '90%', height: '80vh', overflow: 'auto' }}
			>
				<div className="cropped-container d-flex flex-column ">
					<div className="cropped-container-image">
						<Cropper
							image={src}
							crop={crop}
							zoom={zoom}
							minZoom={1}
							objectFit="contain"
							aspect={bannerType?.width / bannerType?.height}
							onCropChange={onCropChange}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							style={{
								containerStyle: { height: '100%' },
								mediaStyle: { width: '40%' },
							}}
						/>
					</div>
					<div className="cropped-container-btn">
						<Button onClick={generateCroppedImage}>Crop and Save</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default ImageCropper;
