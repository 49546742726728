import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';

import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineResetTv } from 'react-icons/md';
import { Tooltip } from 'primereact/tooltip';
import { HiTrendingUp } from 'react-icons/hi';

import { TieredMenu } from 'primereact/tieredmenu';
import { getAxios } from '../../axios/Axios';
import request from '../../request/request';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../loader/Loader';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

const GlobalCategoryList = () => {
	const [globalCategoryList, setGlobalCategoryList] = useState();
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [levelType, setLevelType] = useState('');
	const navigate = useNavigate();
	// const [isResetting, setIsResetting] = useState(false);
	const levelItems = [
		// { name: 'Reset Level', value: '' },
		{ name: 'Level 1', value: '1' },
		{ name: 'Level 2 ', value: '2' },
		{ name: 'Level 3 ', value: '3' },
	];

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: {
					page: currentPage,
					rows,
					more_details: 1,
					term: searchText,
					level: levelType,
				},
			});
			if (response?.data.apiresponse?.type === 'OK') {
				setGlobalCategoryList(response?.data);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [searchText, currentPage, rows, levelType]);

	useEffect(() => {
		fetchData();
	}, [first, currentPage, rows]);

	const handleSearch = useCallback(() => {
		fetchData();
	}, [searchText, levelType]);

	const handleResetFilter = useCallback(() => {
		setSearchText('');
		setLevelType('');
		fetchData();
	}, []);

	const onPageChange = (event) => {
		setFirst(event.first);
		setCurrentPage(event.page + 1);
		setRows(event.rows);
	};

	const renderCount = (rowData, { rowIndex }) => {
		const serialNumber = first + rowIndex + 1;
		return (
			<div className="flex justify-content-evenly gap-1">{serialNumber}</div>
		);
	};

	const renderCategoryName = (rowData) => {
		return (
			<div className="webiste-detail-container">
				<div className="d-flex gap-1">
					<div>{rowData?.category_name}</div>
					{rowData?.product_placement[0]?.position_type_id === 'HOME_PAGE' && (
						<div className="category-details-id">
							<HiTrendingUp fill="green" stroke="green" width={40} />
							{/* <img  src='/images/trend_cats.svg'  width={20} /> */}
						</div>
					)}
				</div>
				<div className="category-details-id">
					<span>{rowData?.product_category_id}</span>
				</div>

				<div className="d-flex gap-2 b2b-wrapper">
					<span className="b2b-span">
				
						{rowData?.classifications?.map((classification, index) => (
							<React.Fragment key={classification.classification_group_id}>
								{classification.classification_group_id}
								{index < rowData.classifications.length - 1 && (
									<span style={{ color: ' #d4d9de' }}> | </span>
								)}
							</React.Fragment>
						))}
					</span>
				</div>
			</div>
		);
	};

	const renderParentCategory = (rowData) => {
		return (
			<div className="webiste-detail-container">
				<div>{rowData?.parent_category_name}</div>
				<div className="category-details-id">
					<span>{rowData?.primary_parent_category_id}</span>
				</div>
			</div>
		);
	};

	const renderIcon = (rowData) => {
		const categoryIcon = rowData.category_contents?.find(
			(content) => content.content_type_id === 'CATEGORY_ICON'
		);
		return categoryIcon ? (
			<div className="d-flex justify-content-center">
				<img
					src={categoryIcon.object_info.small.public_url}
					alt="Category Icon"
					// width={100}
					height={50}
				/>
			</div>
		) : (
			<div className="d-flex justify-content-center">
				<img src="/images/no-icon.webp" alt="Category Icon" height={50} />
			</div>
		);
	};
	const renderActions = (rowData) => {
		const menu = useRef(null);
		const navigate = useNavigate();
		const items = [
			{
				label: 'Edit',
				icon: 'pi pi-file-edit',
				command: () => {
					navigate(
						`/update-product-category?id=${rowData?.product_category_id}`,
						{ state: rowData?.product_category_id }
					);
				},
			},

			{
				label: 'Create Category Advertisment',
				icon: 'pi pi-file-edit',
				command: () => {
					navigate(
						`/create-category-advertisment?id=${rowData?.product_category_id}`,
						{ state: rowData?.product_category_id }
					);
				},
			},
		];
		return (
			<div className=" cursor-pointer flex justify-content-center">
				<TieredMenu model={items} popup ref={menu} breakpoint="767px" />
				<span
					className="global-category-action"
					icon="pi pi-check"
					aria-label="Filter"
					label="Show"
					onClick={(e) => menu.current.toggle(e)}
				>
					<PiDotsThreeOutlineVerticalBold />
				</span>
			</div>
		);
	};

	const renderDescription = (rowData) => {
		const categoryBanner = rowData.category_contents?.filter(
			(content) => content.content_type_id === 'CATEGORY_BANNER'
		);

		return (
			<div className=" d-flex flex-column">
				<div>{rowData?.description}</div>
				<div className="d-flex  align-items-start gap-1">
					{categoryBanner?.map((fileURL, index) => {
						return (
							<div key={index} className="testing-banner align-items-start  ">
								<img
									src={fileURL?.object_info?.small?.public_url}
									alt={`Banner Preview ${index}`}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<div>
			{loading && <Loader />}
			<div className="global-category-heading d-flex justify-content-between ">
				<p className="h2">Global Category List</p>
				<div
					className="global-category-heading-addBtn"
					onClick={() => {
						navigate('/add-new-category');
					}}
				>
					<div className="global-category-heading-btn">
						<FaPlus
							style={{
								stroke: '#fff',
								fill: '#fff',
								width: '20px',
								height: '20px',
							}}
						/>
					</div>
					<p>Add New Category</p>
				</div>
			</div>

			<div className="m-2 d-flex gap-2 justify-content-between align-items-center  w-100">
				<div className="pr-3">
					<span className="font-bold ">Total Records:</span>
					<span> {globalCategoryList?.metadata?.total} </span>
				</div>
				<div
					style={{ width: '75%' }}
					className="d-flex gap-2 flex-row  justify-content-end align-items-center  "
				>
					<div className="d-flex gap-2 cursor-pointer">
						<div className="field mx-3">
							<label htmlFor="description">Level</label>
							<Dropdown
								value={levelType}
								onChange={(e) => setLevelType(e.value)}
								options={levelItems}
								optionLabel="name"
								placeholder="Select Level type"
								className="w-full md:w-14rem md:h-3rem"
							/>
						</div>
					</div>
					<div className="d-flex gap-2 mt-3">
						<span className="p-input-icon-left d-flex gap-2">
							<i className="pi pi-search" />
							<InputText
								type="search website"
								value={searchText}
								onInput={(e) => setSearchText(e.target.value)}
								placeholder="Search..."
								className="p-inputtext-sm md:h-3rem"
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>

							<Button
								variant="outline-success"
								disabled={loading}
								onClick={handleSearch}
								className="md:w-10rem"
							>
								Search
							</Button>
						</span>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<Button
							variant=""
							disabled={loading}
							onClick={!loading ? fetchData : null}
							className="md:w-8rem d-flex gap-1 align-items-center justify-content-center refresh-btn"
						>
							<IoMdRefresh size="1.3em" />
							Refresh
						</Button>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<span data-pr-tooltip="Reset" data-pr-position="top">
							<MdOutlineResetTv
								style={{
									stroke: '#fff',
									fill: 'green',
									width: '50px',
									height: '50px',
								}}
								onClick={handleResetFilter}
								size="1.3em"
							/>
						</span>
						<Tooltip target="[data-pr-tooltip]" />
					</div>
				</div>
			</div>

			<DataTable value={globalCategoryList?.record} showGridlines>
				<Column body={renderCount} header="S.No" />
				<Column style={{ textAlign: 'center' }} field="level" header="Level" />
				<Column body={renderIcon} header="Category Icon" />
				<Column body={renderCategoryName} header="Category Name" />
				<Column body={renderParentCategory} header="Parent Category" />
				<Column
					style={{ maxWidth: '300px' }}
					body={renderDescription}
					header="Description"
				/>
				<Column
					field="product_category_type_id"
					header="Product Category Type ID"
				/>
				<Column body={renderActions} header="Action" />
			</DataTable>

			<Paginator
				first={first}
				rows={rows}
				totalRecords={globalCategoryList?.metadata?.total}
				rowsPerPageOptions={[50, 100, 150, 200, 250]}
				onPageChange={onPageChange}
			/>
		</div>
	);
};

export default GlobalCategoryList;
