import React, { useCallback, useEffect, useState } from 'react';
import UpdateProductForm from './UpdateProductForm';
import UpdateProductData from './UpdateProductData';
import { useLocation } from 'react-router-dom';
import request from '../../request/request';
import { getAxios } from '../../axios/Axios';

const UpdateProductParent = () => {
	const location = useLocation();
	const product_id = location.state;
	const [productData, setProductData] = useState();
	const [dropDownData, setDropDownData] = useState({
		product_type: [],
		category_list: [],
	});

	//State for Image Cropper

	// const [crop, setCrop] = useState({ x: 0, y: 0 });
	// const [zoom, setZoom] = useState(1);
	// const [completedCrop, setCompletedCrop] = useState(null);
	// const [src, setSrc] = useState(null);



	const fetchData = useCallback(async () => {
		try {
			const [productResponse, productTypeResponse, categoryResponse] =
				await Promise.all([
					getAxios(`${request.getProduct}`, {
						params: { product_id },
					}),
					getAxios(`${request.productType}?$limit=200&$offset=0&$match=all`),

					getAxios(`${request.categoryList}`),
				]);
			if (productResponse?.data.apiresponse?.type === 'OK') {
				setProductData(productResponse?.data?.record);
			}

			setDropDownData({
				product_type: productTypeResponse?.data?.items,
				category_list: categoryResponse?.data?.items,
			});
		} catch (error) {
			console.log(error);
		}
	}, [product_id]);

	useEffect(() => {
		fetchData();
	}, []);

	const handleUpdateProduct = useCallback(async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}
	}, []);

	return (
		<div className="w-100 d-flex flex-row gap-5">
			<UpdateProductData data={productData} />
			<UpdateProductForm
				data={productData}
				dropDownData={dropDownData}
				handleUpdateProduct={handleUpdateProduct}
			/>
		</div>
	);
};

export default UpdateProductParent;
