// const BASE_URL="https://api.babusiya.com"
const BASE_URL = process.env.REACT_APP_API_SERVER_SSR;

export const EEZYERP_REDIRECT =
	'https://babusiya.erp.eezyerp.in/content/control/EditWebSite?';

const request = {
	loginForm: BASE_URL + '/account/verify_user',
	whoami: BASE_URL + '/account/whoami',
	logout: BASE_URL + '/account/logoff',
	addUrlPattern: BASE_URL + '/page/add-url-pattern',
	listUrlPatter: BASE_URL + '/page/list_url_patterns',
	getUrlTags: BASE_URL + '/page/get-page-tags',
	editUrlPattern: BASE_URL + '/page/edit-url-pattern',
	deleteUrlPattern: BASE_URL + '/page/delete_url_pattern',
	getPost: BASE_URL + '/posts/get_post',
	deletePost: BASE_URL + '/activity/delete_post',
	updatePost: BASE_URL + '/posts/get_post_update',
	uploadPostAttachments: BASE_URL + '/activity/upload_post_attachments',
	createPost: BASE_URL + '/activity/create_post',
	getInquiry: BASE_URL + '/inquiry/get_inquiry',
	getwebsiteList: BASE_URL + '/website/list_websites',
	getSupplierList: BASE_URL + '/website/get_supplier_category',
	globalCategoryList: BASE_URL + '/website/global_category_list',
	categoryMapping: BASE_URL + '/website/category_mapping',
	updatePoductCategory: BASE_URL + '/catalog/updateProductCategory',
	deleteContentCategory: BASE_URL + '/catalog/deleteCategoryContent',
	addCatlogCategory: BASE_URL + '/catalog/addCategory',
	getProtalProducts: BASE_URL + '/portal/getPortalProducts',
	createProductPlacement: BASE_URL + '/portal/createProductPlacement',
	deleteProductPlacement: BASE_URL + '/portal/deleteProductPlacement',
	portalPositionMaster: BASE_URL + '/yancy/api/PortalPositionMaster',
	getProduct: BASE_URL + '/catalog/getProduct',
	productType: BASE_URL + '/yancy/api/ProductType',
	productPriceUOM: BASE_URL + '/yancy/api/Uom',
	termUOM: BASE_URL + '/catalog/termUomList',
	categoryList: BASE_URL + '/catalog/CategoryList',
	getAllParty : BASE_URL + '/account/getAllParties',
	categoryClassification : BASE_URL + '/portal/getCategoryClassificationGroup',
	getMappedCategory : BASE_URL + '/portal/get_mapped_category'
};
export default request;
