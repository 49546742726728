import React, { createContext, useState } from 'react'
const LoadingContext=createContext({
    loading:false,
    loadingEnabled:()=>{
    },
    loadingDisabled:()=>{
    }
})
function Provider({children}) {
    const [loading,setLoading]=useState(false)
    const loadingEnabled=()=>{
         setLoading(true)
    }
    const loadingDisabled=()=>{
          setLoading(false)
    }
    const loadingContextValue={
        loading:loading,
        loadingEnabled:loadingEnabled,
        loadingDisabled:loadingDisabled
    }
  return (
    <LoadingContext.Provider value={loadingContextValue}>{children}</LoadingContext.Provider>
  )
}

export default Provider
export {LoadingContext}