import React, { useCallback, useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { postAxios } from "../../axios/Axios";
import request from "../../request/request";
import Constants from "../constants/Constants";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function UrlScreen({ changeToast }) {
  const navigate = useNavigate();
  const [metaArray, setMetaArray] = useState([
    { metaId: "new", tagName: "", tagContent: "", systemGenerated: false },
  ]);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false); // Track changes

  const url_id = window.location.pathname.split("/")[2];
  const isEditing = !!url_id;

  const fetchTobeUpdateData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await postAxios(request.getUrlTags, { url_id });
      const result = res?.data?.data;

      setTitle(result?.title || "");
      setDescription(result?.description || "");
      setUrl(result?.url_pattern || "");
      const mappedMetaArray = result?.metatags.map((item) => ({
        metaId: item.meta_id,
        tagName: item.name,
        tagContent: item.value,
        systemGenerated: item.system_generated === 1 ? true : false,
      })) || [
        { metaId: "new", tagName: "", tagContent: "", systemGenerated: false },
      ];

      setMetaArray(mappedMetaArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [url_id]);

  useEffect(() => {
    if (isEditing) {
      fetchTobeUpdateData();
    }
  }, [url_id, isEditing, fetchTobeUpdateData]);

  // const addBtn = () => {
  //   setMetaArray([
  //     ...metaArray,
  //     { metaId: "", tagName: "", tagContent: "", systemGenerated: false },
  //   ]);
  //   setHasChanges(true);
  // };

  const addBtn = () => {
    let insertIndex = metaArray.length;
    for (let i = metaArray.length - 1; i >= 0; i--) {
      if (!metaArray[i].systemGenerated) {
        insertIndex = i + 1;
        break;
      }
    }
    setMetaArray((prevMetaArray) => [
      ...prevMetaArray.slice(0, insertIndex),
      { metaId: "new", tagName: "", tagContent: "", systemGenerated: false },
      ...prevMetaArray.slice(insertIndex),
    ]);

    setHasChanges(true);
  };

  const crossBtn = (indexToRemove) => {
    const updatedMetaArray = metaArray.filter(
      (_, index) => index !== indexToRemove
    );
    setMetaArray(updatedMetaArray);
    setHasChanges(true);
  };

  const handleChange = () => {
    setHasChanges(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validating required fields
    if (title.length === 0 || description.length === 0 || url.length === 0) {
      changeToast(
        Constants.TOAST_WARNING,
        Constants.TOAST_WARNING_TITLE,
        "All fields are required"
      );
      return;
    }

    const data = {
      title: title,
      description: description,
      url_pattern: url,
      metaTags: metaArray
        .filter((item) => {
          return item.systemGenerated === false;
        })
        .map((item) => ({
          tagName: item.tagName,
          tagContent: item.tagContent,
        })),
      url_id: isEditing ? url_id : undefined,
    };

    setLoading(true);
    try {
      if (isEditing) {
        const res = await postAxios(request.editUrlPattern, data);
        const result = res?.data?.apiresonce;
        if (result.status === "OK") {
          navigate("/url-list");
        }
      } else {
        const res = await postAxios(request.addUrlPattern, data);
        const result = res?.data?.apiresonce;

        if (result.status === "OK") {
          setTitle("");
          setDescription("");
          setUrl("");
          setMetaArray([
            { metaId: "", tagName: "", tagContent: "", systemGenerated: false },
          ]);
          changeToast(
            Constants.TOAST_SUCCESS,
            Constants.TOAST_SUCCESS_TITLE,
            result.message
          );
        } else {
          changeToast(
            Constants.TOAST_ERROR,
            Constants.TOAST_ERROR_TITLE,
            result.message
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      changeToast(
        Constants.TOAST_ERROR,
        Constants.TOAST_ERROR_TITLE,
        "Failed to add/update URL pattern"
      );
    } finally {
      setLoading(false);
      setHasChanges(false);
    }
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-center m-auto url-screen">
      {loading && <Loader />}
      <div className="card w-75">
        <div className="card-body">
          <p className="text-center" style={{fontWeight: 700 }}>
            {isEditing ? "Update" : "Add"} URL Screen
          </p>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="text"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                  handleChange();
                }}
                className="form-control"
                placeholder="URL Pattern"
                required
              />
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  handleChange();
                }}
                placeholder="Title"
                required
              />
            </div>
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  handleChange();
                }}
                required
              ></textarea>
            </div>
            <div className="d-flex justify-content-between">
              <p className="ms-2" style={{ color: "grey", fontWeight: 600 }}>
                Meta Tags
              </p>
              <Button
                size="sm"
                variant="link"
                onClick={addBtn}
                className="addMetaBtn"
                style={{
                  fontSize: "13px",
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                <AiFillPlusCircle style={{ fontSize: "30px" }} /> Add Meta Tags
              </Button>
            </div>
            {metaArray.map((meta, index) => (
              <>
                <div key={index} className="d-flex flex-column mb-3">
                  <div className="d-flex">
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={meta.tagName}
                        disabled={meta.systemGenerated}
                        onChange={(e) => {
                          const updatedMetaArray = [...metaArray];
                          updatedMetaArray[index].tagName = e.target.value;
                          setMetaArray(updatedMetaArray);
                          handleChange();
                        }}
                      />
                      {isEditing && meta.metaId && meta.metaId !== "new" ? (
                        <p
                          style={{
                            color: "black",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          Meta Id:{" "}
                          <span style={{ color: "grey" }}>{meta.metaId}</span>
                        </p>
                      ) : meta.metaId === 'new' ? null : (
                        <p
                          style={{
                            color: "grey",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          System Generated
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Content"
                        value={meta.tagContent}
                        disabled={meta.systemGenerated}
                        onChange={(e) => {
                          const updatedMetaArray = [...metaArray];
                          updatedMetaArray[index].tagContent = e.target.value;
                          setMetaArray(updatedMetaArray);
                          handleChange();
                        }}
                      />
                    </div>
                    {!meta.systemGenerated && (
                      <div
                        onClick={() => crossBtn(index)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          left: "-40px",
                          top: "14px",
                        }}
                      >
                        <RxCrossCircled size="1.5em" />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className="d-flex gap-4">
              <button
                className="btn w-100 text-white"
                style={{ backgroundColor: "grey" }}
                onClick={() => {
                  navigate("/url-list");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-100 text-white"
                style={{ backgroundColor: "#f6aa1f" }}
                disabled={isEditing && !hasChanges}
              >
                {isEditing ? "UPDATE URL" : "ADD URL"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UrlScreen;
