import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { postAxios } from '../../axios/Axios';
import request from '../../request/request';
import ImageUploading from 'react-images-uploading';
import CaptionModal from '../modal/CaptionModal';
import { Button } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import {
	postingStatusData,
	postingTypeData,
} from '../../utils/defaultConstants';
import Constants from '../constants/Constants';

function CreatePost({ changeToast }) {
	const [modalShow, setModalShow] = useState(false);
	const [titleList, setTitleList] = useState([]);
	const [postAttachment, setPostAttachment] = useState({
		latitude: '',
		longitude: '',
		images: [],
	});
	const maxNumber = 69;
	const [showImages, setShowImages] = useState(false);
	const [formData, setFormData] = useState({
		cta: '',
		message: '',
		party_id: '',
		posting_status: '',
		text1: '',
		text2: '',
		text3: '',
		title: '',
		type: '',
	});

	const onChange = (imageList, addUpdateIndex) => {
		const updatedImageList = imageList.map((value, index) => {
			return {
				...value,
				id: index,
			};
		});
		setPostAttachment((prev) => ({
			...prev,
			images: updatedImageList,
		}));
		setModalShow(true);
		console.log(addUpdateIndex);
	};

	const resetFunction = () => {
		setFormData({
			cta: '',
			message: '',
			party_id: '',
			posting_status: '',
			text1: '',
			text2: '',
			text3: '',
			title: '',
			type: '',
		});
		setPostAttachment({
			latitude: '',
			longitude: '',
			images: [],
		});
		setTitleList([]);
	};
	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = {
			cta: formData?.cta,
			message: formData?.message,
			party_id: formData?.party_id,
			posting_status: formData?.posting_status,
			text1: formData?.text1,
			text2: formData?.text2,
			text3: formData?.text3,
			title: formData?.title,
			type: formData?.type,
		};
		const res = await postAxios(request.createPost, data);
		if (res?.data?.apiresponse.type === 'OK') {
			changeToast(
				Constants.TOAST_SUCCESS,
				Constants.TOAST_SUCCESS_TITLE,
				res?.data?.apiresponse?.message
			);
			const formData = new FormData();
			formData.append('post_id', res.data.record.id);
			postAttachment?.images?.forEach((image) => {
				formData.append('medialist', image.file);
			});

			titleList.forEach((title) => {
				if (title.trim()) {
					formData.append('titlelist', title);
				}
			});
			formData.append('latitude', postAttachment?.latitude);
			formData.append('longitude', postAttachment?.longitude);
			const uploadRes = await postAxios(
				request.uploadPostAttachments,
				formData
			);

			if (uploadRes?.data?.apiresponse?.type === 'OK') {
				changeToast(
					Constants.TOAST_SUCCESS,
					Constants.TOAST_SUCCESS_TITLE,
					uploadRes?.data?.apiresponse?.message
				);
				resetFunction();
			} else {
				changeToast(
					Constants.TOAST_ERROR,
					Constants.TOAST_ERROR_TITLE,
					uploadRes?.data?.apiresponse?.message
				);
			}
		} else {
			changeToast(
				Constants.TOAST_ERROR,
				Constants.TOAST_ERROR_TITLE,
				res?.data?.apiresponse?.message
			);
		}
	};

	const handleRemoveImage = (index) => {
		setPostAttachment((prev) => ({
			...prev,
			images: prev.images.filter((_, i) => i !== index),
		}));
	};

	return (
		<div className="create-post container">
			<div className="">
				<CaptionModal
					show={modalShow}
					onHide={() => setModalShow(false)}
					image={postAttachment?.images}
					setTitleList={setTitleList}
					setShowImages={setShowImages}
					handleRemoveImage={handleRemoveImage}
					titleList={titleList}
				/>
				<div className="card-body">
					<p className="fs-2">Create A Post</p>
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-6">
								<div className="field">
									<label className="form-required">Party Id</label>
									<InputText
										id="partyId"
										value={formData?.party_id}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												party_id: e.target.value,
											}))
										}
										required
										autoFocus
										placeholder="Please Enter The Party Id"
									/>
								</div>
								<div className="field">
									<label className="form-required">Posting Status</label>
									<Dropdown
										optionLabel="name"
										value={formData?.posting_status}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												posting_status: e.value,
											}))
										}
										required
										autoFocus
										placeholder="Select a Status"
										options={postingStatusData}
										className="w-full md:w-100 text-uppercase"
									/>
								</div>
								<div className="field">
									<label className="form-required">Posting Type</label>
									<Dropdown
										optionLabel="name"
										value={formData?.type}
										onChange={(e) =>
											setFormData((prev) => ({ ...prev, type: e.value }))
										}
										required
										autoFocus
										options={postingTypeData}
										placeholder="Select Posting Type"
										className="w-full md:w-100 text-uppercase"
									/>
								</div>

								<div className="field">
									<label htmlFor="latitude">Latitude</label>
									<InputText
										id="latitude"
										value={postAttachment?.latitude}
										onChange={(e) =>
											setPostAttachment((prev) => ({
												...prev,
												latitude: e.target.value,
											}))
										}
										placeholder="Please Enter The Latitude"
									/>
								</div>

								<div className="field">
									<label htmlFor="longitude">Longitude</label>
									<InputText
										id="longitude"
										value={postAttachment?.longitude}
										onChange={(e) =>
											setPostAttachment((prev) => ({
												...prev,
												longitude: e.target.value,
											}))
										}
										placeholder="Please Enter The Longitude"
										name="longitude"
									/>
								</div>
								<div className="field">
									<label>Upload Images/Videos</label>
									<div className="col-12 d-flex align-items-center gap-2 border rounded">
										Click on "Choose File" button to upload file
										<ImageUploading
											multiple
											value={postAttachment?.images}
											onChange={onChange}
											maxNumber={maxNumber}
											dataURLKey="data_url"
										>
											{({ onImageUpload, isDragging, dragProps }) => (
												<div className="upload__image-wrapper">
													<Button
														style={isDragging ? { color: 'red' } : null}
														onClick={onImageUpload}
														{...dragProps}
														type="button"
														variant="outline-secondary"
													>
														Choose files
													</Button>{' '}
												</div>
											)}
										</ImageUploading>
										{/* <input type="file" id="file" multiple onChange={handleChangeFile} /> */}
									</div>
								</div>

								<div className="d-flex flex-wrap gap-3">
									{showImages && (
										<>
											{postAttachment?.images?.map((image, index) => (
												<div
													key={index}
													className="image-item position-relative"
												>
													<img
														className=""
														style={{ width: '100px', height: '100px' }}
														src={image.data_url}
														alt=""
														width="100"
													/>

													<PrimeButton
														className="position-absolute category-preview-newTileBtn "
														type="button"
														icon="pi pi-times"
														rounded
														text
														severity="danger"
														onClick={() => handleRemoveImage(index)}
														aria-label="Cancel"
													/>
												</div>
											))}
										</>
									)}
								</div>
							</div>

							<div className="col-6">
								<div className="field">
									<label className="form-required">Title</label>
									<InputText
										id="title"
										value={formData?.title}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												title: e.target.value,
											}))
										}
										required
										autoFocus
										placeholder="Please Enter The Title"
									/>
								</div>
								<div className="field">
									<label className="form-required">Description</label>
									<InputTextarea
										id="message"
										required
										value={formData?.message}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												message: e.target.value,
											}))
										}
										rows={3}
										cols={20}
										placeholder="Please Enter The Description Here..."
									/>
								</div>
								<div className="field">
									<label htmlFor="text1">Text1</label>
									<InputTextarea
										id="text1"
										placeholder="Please Enter The text1"
										value={formData?.text1}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												text1: e.target.value,
											}))
										}
										rows={2}
									/>
								</div>
								<div className="field">
									<label htmlFor="text2">Text2</label>
									<InputTextarea
										id="text2"
										value={formData?.text2}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												text2: e.target.value,
											}))
										}
										rows={2}
										placeholder="Please Enter The text2"
									/>
								</div>
								<div className="field">
									<label htmlFor="text3">Text3</label>
									<InputTextarea
										id="text3"
										value={formData?.text3}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												text3: e.target.value,
											}))
										}
										rows={2}
										placeholder="Please Enter The text3"
									/>
								</div>
								<div className="field">
									<label htmlFor="message">CTA</label>
									<InputText
										id="message"
										value={formData?.cta}
										onChange={(e) =>
											setFormData((prev) => ({ ...prev, cta: e.target.value }))
										}
										placeholder="Please Enter The CTA Here..."
									/>
								</div>
							</div>

							<div className="col-6 text-center w-100">
								<div className="d-flex justify-content-end gap-2">
									<Button
										variant="outline-danger"
										className="md:w-6rem md:h-3rem"
									>
										Cancel
									</Button>
									<Button
										variant="outline-warning"
										className="md:w-6rem md:h-3rem"
										onClick={resetFunction}
									>
										Reset
									</Button>
									<Button
										variant="success"
										icon="pi pi-check"
										className="md:w-6rem md:h-3rem"
										type="submit"
									>
										Submit
									</Button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default CreatePost;
