import React from 'react'

function Loader() {
  return (
    <div className='loader bg-opacity-75'>
    <button className="btn btn-primary" type="button" disabled>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Loading...
    </button>
</div>
    
  )
}

export default Loader