import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import request from '../../request/request';
import { Paginator } from 'primereact/paginator';
import { postAxios } from '../../axios/Axios';
import moment from 'moment/moment';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import Loader from '../loader/Loader';
import { LoadingContext } from '../../context/Provider';
export default function GridLinesDemo() {
    const [products, setProducts] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState("")
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [senderName, setSenderName] = useState("")
    const [senderEmail, setSenderEmail] = useState("")
    const [senderMobile, setSenderMobile] = useState("")
    const [recieverParty, setRecieverParty] = useState("")
    const [recieverName, setRecieverName] = useState("")
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null)
    const [dataBySearch, setDataBySearch] = useState(false)
    const [myApiData, setMyApiData] = useState([])
    const loaderLoading=useContext(LoadingContext)
    useEffect(() => {
        fetchData()
    }, []);
    useEffect(() => {
        fetchData()
        loaderLoading.loadingEnabled()
    }, [rows, first])
    console.log("myApiData",myApiData)
    const fetchData = async () => {
        const data = {
            meta: { jtStartIndex: first, jtPageSize: rows },
        }
        const data1={
            ...myApiData,
            meta: { jtStartIndex: first, jtPageSize: rows },
        }
        let res
        if(dataBySearch){
            res = await postAxios(request.getInquiry,data1)
        }else{
         res = await postAxios(request.getInquiry,data)
        }
        setProducts(res.data.records)
        setTotalRecords(res.data.metadata.total)
        loaderLoading.loadingDisabled()
    }
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    const messageBody = (rowData) => {
        console.log("row", rowData)
        return (
            <div dangerouslySetInnerHTML={{ __html: rowData.message }}>

            </div>
        )
    }
    const resetFunction = () => {
        setTitle("")
        setMessage("")
        setSenderName("")
        setSenderEmail("")
        setSenderMobile("")
        setRecieverParty("")
        setRecieverName("")
        setFromDate(null)
        setToDate(null)
        setDataBySearch(false)
    }
    const postingByBody = (rowData) => {
        return (
            <div>
                <div>
                    {rowData.sender_name}
                </div>
                <div>
                    Email-<span>{rowData.sender_email}</span>
                </div>
                <div>
                    <span>Mobile No-</span><span>{rowData.sender_mobile}</span>
                </div>
            </div>
        )
    }
    const generatedBody = (rowData) => {
        console.log("rows", rowData.generated)
        return (
            <div>
                {moment(rowData.generated * 1000).format('DD/MM/YYYY')}
                <br />
                {moment(rowData.generated * 1000).format('LTS')}
                <br />
                {moment(rowData.generated * 1000).startOf('hour').fromNow()}
            </div>
        )
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setDataBySearch(true)
        let fieldData = {
        }
        if (title.length > 0) {
            fieldData.title = title
        }
        if (message.length > 0) {
            fieldData.message = message
        }
        if (senderName.length > 0) {
            fieldData.sender_name = senderName
        }
        if (senderMobile.length > 0) {
            fieldData.sender_mobile = senderMobile
        }
        if (senderEmail.length > 0) {
            fieldData.sender_email = senderEmail
        }
        if (recieverParty.length > 0) {
            fieldData.receiver_party_id = recieverParty
        }
        if (recieverName.length > 0) {
            fieldData.receiver_name = recieverName
        }
        if(fromDate){
            fieldData.date_from=moment(new Date(fromDate)).format("YYYY-MM-DD")
        }
        if(toDate){
            fieldData.date_to=moment(new Date(toDate)).format("YYYY-MM-DD") 
        }
        const data = {
            ... fieldData, meta: { jtStartIndex: first, jtPageSize: rows }
        }
        setMyApiData(fieldData)
        const res = await postAxios(request.getInquiry, data)
        setProducts(res.data.records)
        setTotalRecords(res.data.metadata.total)
        loaderLoading.loadingDisabled()
    }
    return (
        <div className="card">
            {loaderLoading.loading &&
            <Loader/>}
            <form onSubmit={handleSubmit}>

                <div className='row p-4'>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder='Enter title'
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <InputText
                                id="message"
                                autoFocus
                                value={message}
                                placeholder="Enter message"
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="sender_name">Sender name</label>
                            <InputText
                                id="sender_name"
                                value={senderName}
                                onChange={(e) => setSenderName(e.target.value)}
                                autoFocus
                                placeholder='Enter sender name'
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="sender_email">Sender email</label>
                            <InputText
                                id="sender_email"
                                value={senderEmail}
                                onChange={(e) => setSenderEmail(e.target.value)}
                                placeholder="Enter sender email"
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="sender_mobile">sender mobile number</label>
                            <InputText
                                id="sender_mobile"
                                autoFocus
                                value={senderMobile}
                                onChange={(e) => setSenderMobile(e.target.value)}
                                placeholder="Enter sender mobile"
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="party">Reciever Party id</label>
                            <InputText
                                id="party"
                                autoFocus
                                value={recieverParty}
                                onChange={(e) => setRecieverParty(e.target.value)}
                                placeholder="Enter Reciever Party id"
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="reciever_name">Reciever name</label>
                            <InputText
                                id="reciever_name"
                                value={recieverName}
                                onChange={(e) => setRecieverName(e.target.value)}
                                placeholder='Enter Reciever Name'
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="from_date">From date</label>
                            <Calendar id='from_date' placeholder='From Date' value={fromDate} onChange={(e) => setFromDate(e.value)} readOnlyInput />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="field">
                            <label htmlFor="to_date">To date</label>
                            <Calendar id='to_date' placeholder='To Date' value={toDate} onChange={(e) => setToDate(e.value)} readOnlyInput />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='button-group-inquiry'>
                            <button className='btn btn-primary mx-1' onClick={resetFunction}>Reset</button>
                            <button className='btn btn-success mx-1' type='submit'>Search</button>
                        </div>
                    </div>
                </div>
            </form>
            
            <div className='m-2'>
                <span className='font-bold'>Total Records:</span><span> {totalRecords}</span>
                </div>

            <DataTable value={products} showGridlines>
                <Column field="title" header="Title"></Column>
                <Column field="" body={messageBody} header="Message"></Column>
                <Column field="category" body={postingByBody} header="Posting BY"></Column>
                <Column field="receiver_name" header="Reciever"></Column>
                <Column field="quantity" body={generatedBody} header="Generated"></Column>
                <Column field="quantity" header="Details"></Column>

            </DataTable>
            <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                onPageChange={onPageChange} />
        </div>
    );
}