import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useCallback, useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import moment from "moment/moment";
import { getAxios, postAxios } from "../../axios/Axios";
import request from "../../request/request";
import { InputText } from "primereact/inputtext";
import { MdDelete } from "react-icons/md";
import { IoMdInformationCircle, IoMdRefresh } from "react-icons/io";
import { FaEdit } from "react-icons/fa";

import { ConfirmDialog } from "primereact/confirmdialog";
import Constants from "../constants/Constants";
import Loader from "../loader/Loader";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function UrlList({ changeToast }) {
  const navigate = useNavigate();
  const [urls, setUrls] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [hoveredMetatags, setHoveredMetatags] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getAxios(request.listUrlPatter, {
        params: {
          page: currentPage,
          rows,
          q: searchText,
        },
      });
      if (res?.data?.apiresponse?.type === "OK") {
        setUrls(res?.data?.records);
        setTotalRecords(res?.data?.metadata?.totalentries);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [currentPage, rows, searchText]);

  console.log(urls);

  useEffect(() => {
    fetchData();
  }, [first, rows, currentPage, searchText, fetchData]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRows(event.rows);
  };

  const generatedBody = (rowData) => {
    return (
      <div>
        {moment(rowData.generated * 1000).format("DD/MM/YYYY")},{" "}
        {moment(rowData.generated * 1000).format("LTS")},{" "}
        {moment(rowData.generated * 1000)
          .startOf("hour")
          .fromNow()}
      </div>
    );
  };

  const handleDelete = (rowData) => {
    setItemToDelete(rowData);
    setConfirmDialogVisible(true);
  };

  const handleView = (rowData) => {
    setVisible(true);
    setCurrentData(rowData);
    fetchCurrentMetaData(rowData?.url_id);
  };

  const acceptDelete = async () => {
    setLoading(true);
    if (!itemToDelete) return;

    try {
      const res = await postAxios(request.deleteUrlPattern, {
        url_id: itemToDelete.url_id,
      });
      if (res?.data?.apiresponse?.type === "OK") {
        changeToast(
          Constants.TOAST_SUCCESS,
          Constants.TOAST_SUCCESS_TITLE,
          res?.data?.apiresponse?.message
        );

        fetchData(); // Refresh data after deletion
      }
    } catch (err) {
      console.log(err);
    } finally {
      setItemToDelete(null);
      setConfirmDialogVisible(false);
      setLoading(false);
    }
  };

  const fetchCurrentMetaData = async (url_id) => {
    try {
      const res = await postAxios(request.getUrlTags, {
        url_id,
      });
      if (res?.status === 200) {
        setHoveredMetatags(res?.data?.data?.metatags);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderActions = (rowData) => {
    return (
      <div className="flex justify-content-evenly">
        <IoMdInformationCircle
          size="1.3em"
          color="#41a7cc"
          cursor="pointer"
          onClick={() => {
            handleView(rowData);
          }}
        />
        <FaEdit
          size="1.3em"
          color="#fcba03"
          cursor="pointer"
          onClick={() => [navigate(`/url-screen/${rowData.url_id}`)]}
        />
        <MdDelete
          size="1.3em"
          color="#cc4141"
          cursor="pointer"
          onClick={() => handleDelete(rowData)}
        />
      </div>
    );
  };

  return (
    <div>
      {loading && <Loader />}
      <p className="h2">My Url List</p>

      <div className="m-2 d-flex justify-content-between align-items-center">
        <div>
          <span className="font-bold">Total Records:</span>
          <span> {totalRecords} </span>
        </div>
        <div className="d-flex gap-2">
          <Button
            variant="outline-success"
            disabled={loading}
            onClick={!loading ? fetchData : null}
            size="md"
          >
            <IoMdRefresh size="1.3em"/>
            Refresh
          </Button>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setSearchText(e.target.value)}
              placeholder="Search..."
              className="p-inputtext-sm"
            />
          </span>
        </div>
      </div>

      <DataTable value={urls} showGridlines>
        <Column field="url_pattern" header="URL Pattern" />
        <Column field="title" header="Title" />
        <Column field="description" header="Description" />
        <Column field="generated" body={generatedBody} header="Generated" />
        <Column body={renderActions} header="Actions" />
      </DataTable>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        onPageChange={onPageChange}
      />

      <ConfirmDialog
        visible={confirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message="Do you want to delete this record?"
        header="Delete Confirmation"
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        accept={acceptDelete}
        reject={() => setConfirmDialogVisible(false)}
      />

      <Dialog
        visible={visible}
        header="Detail"
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div>
          <ul className="list-unstyled">
            <li className="text-dark h5">
              Title: <span className="h6">{currentData.title}</span>
            </li>
            <li className="text-dark h5">
              URL Pattern: <span className="h6">{currentData.url_pattern}</span>
            </li>
            <li className="text-dark h5">
              Description: <span className="h6">{currentData.description}</span>
            </li>
          </ul>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Content</th>
              </tr>
            </thead>
            <tbody>
              {hoveredMetatags.map((tag, index) => (
                <tr key={index}>
                  <td>{tag.name}</td>
                  <td>{tag.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  );
}

export default UrlList;
