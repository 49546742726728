import React from 'react';
import { useRef, memo } from 'react';
import { Modal, Button } from 'react-bootstrap';

function CaptionModal(props) {
	const captionImage = useRef(null);
	const latestImage = props.image?.[props.image.length - 1];

	const captionInputRef = useRef(null);
	const doneBtn = () => {
		
		const latestTitle = captionInputRef.current?.value || '';
		const updatedTitlesArray = [...(props?.titleList || []), latestTitle];

		props.setTitleList(updatedTitlesArray);
		props.setShowImages(true);
		props.onHide();
	};

	const handleCloseModal = () => {
		const latestImageIndex = props.image.length - 1;
		props.handleRemoveImage(latestImageIndex);
		props.onHide();
	};

	return (
		<Modal
			{...props}
			className="modal-caption"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Modal heading
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div ref={captionImage}>
					{latestImage && (
						<div className="mb-3">
							<input
								placeholder="Add Caption"
								className="caption_input"
								type="text"
								ref={captionInputRef}
							/>
							<img className="w-100 mt-2" src={latestImage.data_url} alt="" />
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleCloseModal}>Close</Button>
				<button className="btn btn-success" onClick={doneBtn}>
					Done
				</button>
			</Modal.Footer>
		</Modal>
	);
}
export default memo(CaptionModal);
