import React, { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import { useNavigate } from 'react-router-dom';
import Constants from '../constants/Constants';

const AddGlobalCategory = ({ changeToast }) => {
	const [selectedOption, setSelectedOption] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}(${item?.product_category_id})`,
				value: item?.product_category_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	};

	const handleAddCategory = useCallback(async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData(e.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        data['parent_category_id'] = selectedOption?.value || '';

		try {
			const response = await postAxios(request.addCatlogCategory, data,{
                headers: {
                    'Content-Type': 'application/json',
                },
            });
			if(response?.data?.apiresponse?.type === 'OK'){
                changeToast(
                    Constants.TOAST_SUCCESS,
                    Constants.TOAST_SUCCESS_TITLE,
                    response?.data?.apiresponse?.message
                );

                e.target.reset();  
                setSelectedOption(null); 
            }else{
                changeToast(
                    Constants.TOAST_ERROR,
                    Constants.TOAST_ERROR_TITLE,
                    response?.data?.apiresponse?.message
                ); 
            }
            setLoading(false)
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [selectedOption]);


	return (
		<div className="add-category">
			<div className=" card add-category-form d-flex align-items-center">
				<h2 className="fw-bold">Add New Category</h2>
				{/* <ConfirmDialog
					visible={confirmVisible}
					onHide={() => setConfirmVisible(false)}
					message="Are you sure you want to change the status Doing this will unlist your category?"
					header="Confirmation"
					icon="pi pi-exclamation-triangle"
					accept={accept}
					reject={reject}
				/> */}
				<div className=" w-100 d-flex justify-content-center p-4 m-auto">
					<div className="card-body">
						<form onSubmit={handleAddCategory}>
							<div className=" mb-3">
								<label htmlFor="" className="form-label">
									Name
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Name"
									name="category_name"
                                    required
								/>
							</div>
							<div className=" mb-3">
								<label htmlFor="" className="form-label">
									Description
								</label>
								<textarea
									type="text"
									className="form-control"
									name="description"
									placeholder="Description"
                                    required
								/>
							</div>
							<div className="form-group mb-5">
								<label htmlFor="" className="form-label">
									Parent Category ID
									{/* <span
										style={{
											color: 'red',
											fontSize: '12px',
											fontWeight: 'bold',
										}}
									>
										{' '}
										(Use with caution)
									</span> */}
								</label>

								<AsyncSelect
									cacheOptions
									loadOptions={loadOptions}
									defaultOptions
									onChange={setSelectedOption}
									value={selectedOption}
                                    required
								/>
							</div>
							{/* <div className="mb-3">
								<label htmlFor="formFile" className="form-label">
									Upload Category Icon
								</label>

								<span
									style={{
										color: '#999',
										fontSize: '12px',
									}}
								>
									{' '}
									(Icon size: 512px/512px)
								</span>
								<input
									className="form-control"
									type="file"
									id="formFile"
									ref={categoryIconInputRef}
									onChange={handleCategoryIconChange}
								/>
							</div> */}

							{/* <div className="mb-3">
								<label htmlFor="formFile" className="form-label">
									Upload Banners
								</label>
								<span
									style={{
										color: '#999',
										fontSize: '12px',
									}}
								>
									{' '}
									(Banner size: 1536px/315px)
								</span>
								<input
									className="form-control"
									type="file"
									id="formFile"
									multiple
									ref={bannerInputRef}
									onChange={handleBannerChange}
								/>
							</div> */}

							{/* <div className="form-check mb-4">
								<input
									className="form-check-input"
									type="checkbox"
									name="enabled"
									value="Y"
									id="flexCheckDefault"
									defaultChecked={data?.enabled === 'Y'}
									checked={isChecked}
									onChange={handleCheckboxChange}
								/>
								 <label className="form-check-label" htmlFor="flexCheckDefault">
									Enable
								</label> 
							</div> */}

							<div className="d-flex prime-react-btn w-100 align-items-center gap-2 ">
								<button
									className="btn w-100 text-white"
									style={{ backgroundColor: 'grey' }}
									onClick={() => {
										navigate('/global-category-list');
									}}
								>
									Go Back
								</button>
								<button
									type="submit"
									className="btn w-100 text-white"
									
								>
									
									{loading ? 'Adding...' : 'Add New Category'}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddGlobalCategory;
