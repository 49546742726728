import React from 'react'

function Editprofile() {
    return (
        <div className='w-100 d-flex align-items-center justify-content-center m-auto'>
            <div className='card w-50'>
                <div className='card-body'>
                    <form>

                    <div className='w-100 h-100'>
                        <p className='text-center'>Edit your profile</p>
                        <div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="Username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-user" id="basic-addon2"></span>
                        </div>
                        <div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="First Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-user" id="basic-addon2"></span>
                        </div><div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="Middle Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-user" id="basic-addon2"></span>
                        </div><div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="Last Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-user" id="basic-addon2"></span>
                        </div><div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="Email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-envelope" id="basic-addon2"></span>
                        </div><div className="input-group mb-3">
                            <input required type="text" className="form-control" placeholder="Mobile" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <span className="input-group-text fas fa-phone" id="basic-addon2"></span>
                        </div>
                        <div>
                            <button type='submit' className='btn w-100 text-white' style={{backgroundColor:"#8cc540"}}>Update</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Editprofile