import './App.scss';
import Login from './components/Login/Login';
import Sidebar from './components/Sidebar/Sidebar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAxios } from './axios/Axios';
import request from './request/request';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Editprofile from './components/EditProfile/Editprofile';
import { Breadcrumb } from 'react-bootstrap';
import Dashboard from './components/Dashobard/Dashboard';
import UrlScreen from './components/UrlScreen/UrlScreen';
// import { ToastContainer, toast } from 'react-toastify';
import { Toast } from 'primereact/toast';
import UrlList from './components/UrlList/UrlList';
import CreatePost from './components/create-post/CreatePost';
import Inquiry from './components/inquiry/Inquiry';
import { LoadingContext } from './context/Provider';
import WebsiteList from './components/WebsiteList/WebsiteList';
import MappedScreen from './components/WebsiteList/MappedScreen';
import GlobalCategoryList from './components/CategoryManagement/GlobalCategoryList';
import { UpdateProductCategory } from './components/CategoryManagement/UpdateProductCategory';
import AddGlobalCategory from './components/CategoryManagement/AddGlobalCategory';
import GlobalProductList from './components/ProductManagement/GlobalProductList';
import UpdateProductParent from './components/ProductManagement/UpdateProductParent';
import CreateProductAdvertisement from './components/ProductManagement/CreateProductAdvertisement';
import PartyListing from './components/partylisting/PartyListing';
import CreatePartyAdvertisment from './components/partylisting/CreatePartyAdvertisment';
import CreateCategoryAdvertisment from './components/CategoryManagement/CreateCategoryAdvertisment';
import AppPostings from './components/app-posting/AppPostings';
function App() {
	const [login, setLogin] = useState(false);
	const [menuShow, setMenuShow] = useState(true);
	const toast = useRef(null);
	const [user, setUser] = useState({
		fullname: '',
		profile_pic: '',
		partyId: '',
	});
	const loadingData = useContext(LoadingContext);

	const fetchData = async () => {
		const res = await getAxios(request.whoami);
		if (res.data.apiresponse.code === 'LOGGEDIN') {
			setUser({
				fullname: res?.data?.person?.fullname,
				profilePic: res?.data?.profile_pic,
				partyId: res?.data?.person?.party_id,
			});
			setLogin(false);
		} else {
			setLogin(true);
		}
	};
	const changeToast = (severity, summary, details) => {
		toast.current.show({
			severity: severity,
			summary: summary,
			detail: details,
			life: 3000,
		});
	};
	useEffect(() => {
		fetchData();
	}, [login]);
	return (
		<div className={loadingData.loading ? 'opacity-50' : ''}>
			<Toast ref={toast} />
			<Router>
				{login ? (
					<Login setLogin={setLogin} changeToast={changeToast} />
				) : (
					<React.Fragment>
						<ProSidebarProvider className="d-none">
							<Sidebar
								setUser={setUser}
								setLogin={setLogin}
								setMenuShow={setMenuShow}
								menuShow={menuShow}
								user={user}
							/>
						</ProSidebarProvider>
						<main
							className={menuShow ? 'menu-bar' : 'menu_bar_out'}
							style={{ marginTop: '58px' }}
						>
							<div className="m-5 pt-4">
								<Breadcrumb />
								<Routes>
									<Route
										path="/dashboard/editprofile"
										element={<Editprofile />}
										exact
									/>
									<Route path="/dashboard" element={<Dashboard />} exact />
									<Route
										path="/url-screen"
										element={<UrlScreen changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/url-screen/:url_id"
										element={<UrlScreen changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/url-list"
										element={<UrlList changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/website-list"
										element={<WebsiteList changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/mapped-screen"
										element={<MappedScreen changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/global-category-list"
										element={<GlobalCategoryList changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/update-product-category"
										element={
											<UpdateProductCategory changeToast={changeToast} />
										}
										exact
									/>

									<Route
										path="/add-new-category"
										element={<AddGlobalCategory changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/create-category-advertisment"
										element={
											<CreateCategoryAdvertisment changeToast={changeToast} />
										}
										exact
									/>
									<Route
										path="/global-product-list"
										element={<GlobalProductList changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/update-product-list"
										element={<UpdateProductParent changeToast={changeToast} />}
										exact
									/>

									<Route
										path="/create-product-advertisement"
										element={
											<CreateProductAdvertisement changeToast={changeToast} />
										}
										exact
									/>
									<Route
										path="/party-listing"
										element={<PartyListing changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/create-party-advertisment"
										element={
											<CreatePartyAdvertisment changeToast={changeToast} />
										}
										exact
									/>
									{/* <Route
										path="/dashboard/app-posting"
										element={<AppPosting />}
										exact
									/> */}
									<Route
										path="/dashboard/app-postings"
										element={<AppPostings changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/dashboard/create-post"
										element={<CreatePost changeToast={changeToast} />}
										exact
									/>
									<Route
										path="/dashboard/inquiry"
										element={<Inquiry />}
										exact
									/>
								</Routes>
							</div>
						</main>
					</React.Fragment>
				)}
			</Router>
		</div>
	);
}

export default App;
