import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';

import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineResetTv } from 'react-icons/md';
import { Tooltip } from 'primereact/tooltip';
import { HiTrendingUp } from 'react-icons/hi';

import { TieredMenu } from 'primereact/tieredmenu';
import { getAxios } from '../../axios/Axios';
import request from '../../request/request';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../loader/Loader';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

const PartyListing = () => {
	const [partyList, setPartyList] = useState();
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [searchPartyId, setSearchPartyId] = useState('');
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [partyType, setPartyType] = useState('');
	const [shouldFetch, setShouldFetch] = useState(false);

	const navigate = useNavigate();

	const partyTypeItems = [
		{ name: 'Person', value: 'PERSON' },
		{ name: 'Party Group', value: 'PARTY_GROUP' },
	];
	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(`${request.getAllParty}`, {
				params: {
					page: currentPage,
					rows,
					party_name: searchText,
					party_type: partyType,
					party_id: searchPartyId,
				},
			});

			if (response?.data.apiresponse?.type === 'OK') {
				setPartyList(response?.data);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [, currentPage, rows, searchText, partyType, searchPartyId]);

	useEffect(() => {
		fetchData();
	}, [first, currentPage, rows]);

	const handleSearch = useCallback(() => {
		fetchData();
	}, [searchText, partyType, searchPartyId]);

	const handleResetFilter = useCallback(async () => {
		setSearchText('');
		setPartyType('');
		setSearchPartyId('');
		setShouldFetch(true);
	}, []);

	useEffect(() => {
		if (shouldFetch) {
			fetchData();
			setShouldFetch(false);
		}
	}, [shouldFetch]);
	const onPageChange = (event) => {
		setFirst(event.first);
		setCurrentPage(event.page + 1);
		setRows(event.rows);
	};

	const renderCount = (rowData, { rowIndex }) => {
		const serialNumber = first + rowIndex + 1;
		return (
			<div className="flex justify-content-evenly gap-1">{serialNumber}</div>
		);
	};

	const renderpartyName = (rowData) => {
		return (
			<div className="webiste-detail-container">
				<div className="d-flex gap-1">
					<div>{rowData?.party_name}</div>
				</div>
				<div className="">
					<span
						className={`${rowData?.party_type_desc === 'Person' ? 'person-class' : 'party-group'}`}
					>
						{rowData?.party_type_desc}
					</span>
				</div>
			</div>
		);
	};

	// const renderParentCategory = (rowData) => {
	// 	return (
	// 		<div className="webiste-detail-container">
	// 			<div>{rowData?.parent_category_name}</div>
	// 			<div className="category-details-id">
	// 				<span>{rowData?.primary_parent_category_id}</span>
	// 			</div>
	// 		</div>
	// 	);
	// };

	const renderIcon = (rowData) => {
		return rowData?.profile_pic ? (
			<div className="d-flex justify-content-center">
				<img
					src={rowData?.profile_pic}
					alt="Category Icon"
					// width={100}
					height={50}
				/>
			</div>
		) : (
			<div className="d-flex justify-content-center">
				<img src="/images/no-icon.webp" alt="Category Icon" height={50} />
			</div>
		);
	};
	const renderActions = (rowData) => {
		const menu = useRef(null);
		const navigate = useNavigate();
		const items = [
			{
				label: 'Edit Party',
				icon: 'pi pi-file-edit',
				// command: () => {
				// 	navigate(
				// 		`/update-product-category?id=${rowData?.product_category_id}`,
				// 		{ state: rowData?.product_category_id }
				// 	);
				// },
			},
			{
				label: 'Create Party Advertisement',
				icon: 'pi pi-file-edit',
				command: () => {
					navigate(`/create-party-advertisment?id=${rowData?.party_id}`, {
						state: rowData?.party_id,
					});
				},
			},
		];
		return (
			<div className=" cursor-pointer flex justify-content-center">
				<TieredMenu model={items} popup ref={menu} breakpoint="767px" />
				<span
					className="global-category-action"
					icon="pi pi-check"
					aria-label="Filter"
					label="Show"
					onClick={(e) => menu.current.toggle(e)}
				>
					<PiDotsThreeOutlineVerticalBold />
				</span>
			</div>
		);
	};

	return (
		<div>
			{loading && <Loader />}
			<div className="global-category-heading d-flex justify-content-between ">
				<p className="h2">Party List</p>
				{/* <div
					className="global-category-heading-addBtn"
					onClick={() => {
						navigate('/add-new-category');
					}}
				>
					<div className="global-category-heading-btn">
						<FaPlus
							style={{
								stroke: '#fff',
								fill: '#fff',
								width: '20px',
								height: '20px',
							}}
						/>
					</div>
					<p>Add New Category</p>
				</div> */}
			</div>

			<div className="m-2 d-flex gap-2 justify-content-between align-items-center  w-100">
				<div className="pr-3">
					<span className="font-bold ">Total Records:</span>
					<span> {partyList?.metadata?.total} </span>
				</div>
				<div
					style={{ width: '75%' }}
					className="d-flex gap-2 flex-row  justify-content-end align-items-center  "
				>
					<div className="d-flex gap-2 cursor-pointer">
						<div className="field mx-3">
							<label htmlFor="description">Party Type</label>
							<Dropdown
								value={partyType}
								onChange={(e) => setPartyType(e.value)}
								options={partyTypeItems}
								optionLabel="name"
								placeholder="Select Party type"
								className="w-full md:w-14rem md:h-3rem"
							/>
						</div>
					</div>
					<div className="d-flex gap-2 mt-3">
						<span className="p-input-icon-left d-flex gap-2">
							<i className="pi pi-search" />
							<InputText
								type="search website"
								value={searchText}
								onInput={(e) => setSearchText(e.target.value)}
								placeholder="Search Party Name"
								className="p-inputtext-sm md:h-3rem"
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>

							<InputText
								type="search website"
								value={searchPartyId}
								onInput={(e) => setSearchPartyId(e.target.value)}
								placeholder="Search by party Id"
								className="p-inputtext-sm md:h-3rem"
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>

							<Button
								variant="outline-success"
								disabled={loading}
								onClick={handleSearch}
								className="md:w-10rem"
							>
								Search
							</Button>
						</span>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<Button
							variant=""
							disabled={loading}
							onClick={!loading ? fetchData : null}
							className="md:w-8rem d-flex gap-1 align-items-center justify-content-center refresh-btn"
						>
							<IoMdRefresh size="1.3em" />
							Refresh
						</Button>
					</div>

					<div className="d-flex gap-3 cursor-pointer mt-3">
						<span data-pr-tooltip="Reset" data-pr-position="top">
							<MdOutlineResetTv
								style={{
									stroke: '#fff',
									fill: 'green',
									width: '50px',
									height: '50px',
								}}
								onClick={handleResetFilter}
								size="1.3em"
							/>
						</span>
						<Tooltip target="[data-pr-tooltip]" />
					</div>
				</div>
			</div>

			<DataTable value={partyList?.records} showGridlines>
				<Column body={renderCount} header="S.No" />
				<Column body={renderIcon} header="Profile Pic" />

				<Column body={renderpartyName} header="Party Name" />
				<Column field="party_id" header="Party ID" />
				<Column field="address" header="Address" />
				<Column field="contact_number" header="Contact Number" />

				<Column body={renderActions} header="Action" />
			</DataTable>

			<Paginator
				first={first}
				rows={rows}
				totalRecords={partyList?.metadata?.total}
				rowsPerPageOptions={[50, 100, 150, 200, 250]}
				onPageChange={onPageChange}
			/>
		</div>
	);
};

export default PartyListing;
