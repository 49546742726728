export const getCroppedImg = async (imageSrc, pixelCrop, bannerType) => {
	const image = new Image();
	image.src = imageSrc;

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const outputWidth = bannerType?.width;
	const outputHeight = bannerType?.height;

	canvas.width = outputWidth;
	canvas.height = outputHeight;
	// Load the image and ensure it is fully loaded before drawing
	const loadImage = new Promise((resolve) => {
		image.onload = () => resolve();
	});

	await loadImage;

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		// pixelCrop.width,
		// pixelCrop.height
		outputWidth,
		outputHeight
	);

	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			resolve(blob);
		}, 'image/jpeg'); // Use 'image/jpeg' as default, but you can also detect image type if needed
	});
};

export const formatDate = (date) => {
	if (!(date instanceof Date) || isNaN(date)) {
		throw new Error('Invalid date');
	}

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
};

export const getValidArray = (key) =>
	Array.isArray(key) && key.length > 0 ? key : null;

import { useRef } from 'react';
export const useDebounce = (callback, delay) => {
  const debounceTimeout = useRef(null);

  return (...args) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const toTitleCase = (text) => {
	return text?.toLowerCase()?.split(" ")?.map((word) => {
		return word?.charAt(0)?.toUpperCase() + word?.slice(1);
	  })
	  .join(" ");
  };