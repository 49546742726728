import React, { useCallback, useEffect, useState } from "react";
import { LuFolderTree } from "react-icons/lu";
import { getAxios } from "../../axios/Axios";
import request, { EEZYERP_REDIRECT } from "../../request/request";
import { Button } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import { Dropdown } from "primereact/dropdown";

const WebsiteList = () => {
  const [websiteListData, setWebsiteListData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedMappingStatus, setSelectedMappingStatus] = useState("ANY");
  const [selectWebsiteType, setSelectWebsiteType] = useState("");
  const navigate = useNavigate();
  const mappingType = [
    { name: "ANY", code: "ANY" },
    { name: "Not Started", code: "NOT_STARTED" },
    { name: "WIP", code: "WIP" },
    { name: "Completed", code: "COMPLETED" },
  ];

  const websiteTypeItems = [
    { name: "Customized Website", code: "Customized Website" },
    { name: "Template Based Website ", code: "Template Based Website" },
  ];
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAxios(request.getwebsiteList, {
        params: {
          page: currentPage,
          rows,
          website_name: searchText,
          ...(selectedMappingStatus?.code !== "ANY" && {
            mapping_status: selectedMappingStatus.code,
          }),
          website_type: selectWebsiteType.code,
        },
      });
    
      if (response?.data.apiresponse?.type === "OK") {
        setWebsiteListData(response?.data?.records);
        setTotalRecords(response?.data?.metadata?.TotalRecordCount);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [currentPage, rows, searchText, selectedMappingStatus, selectWebsiteType]);

  useEffect(() => {
    fetchData();
  }, [first, rows, currentPage]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRows(event.rows);
  };

  const hanldeWebisteSearch = useCallback(async () => {
    await fetchData();
  }, [searchText, selectedMappingStatus, selectWebsiteType]);

  const navigateSupplierScreen = useCallback((data) => {
    navigate(`/mapped-screen?id=${data?.website_id}`, { state: data });
  }, []);

  const renderWebsiteDetails = (rowData) => {
    return (
      <div className="webiste-detail-container">
        <div>{rowData?.website_name}</div>
        <div className="website-details-id">
          <span>Website id : </span>
          <a
            href={`${EEZYERP_REDIRECT}webSiteId=${rowData?.website_id}`}
            target="_blank" rel="noreferrer"
          >
            {rowData?.website_id}
          </a>
        </div>
      </div>
    );
  };

  const renderActions = (rowData) => {
    return (
      <div className="flex justify-content-evenly gap-1">
        <LuFolderTree
          size="1.3em"
          cursor="pointer"
          onClick={() => navigateSupplierScreen(rowData)}
        />
      </div>
    );
  };

  const renderProgressbar = (rowData) => {
    let progressBar =
      rowData?.total_category_count / rowData?.total_mapped_category_count;

    if (isNaN(progressBar) || !isFinite(progressBar)) {
      progressBar = 0;
    }
    return (
      <div className="d-flex w-100 gap-1 align-items-center">
        <div className="progress  w-100">
          <div
            className={`progress-bar bg-success `}
            role="progressbar"
            style={{ width: `${progressBar}%` }}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {progressBar}%
          </div>
        </div>
        <span className="mapping-count">
          {" "}
          {rowData?.total_mapped_category_count}/{rowData?.total_category_count}{" "}
        </span>
      </div>
    );
  };

  const renderWebsiteHost = (rowData) => {
    const websiteHost = rowData?.website_host || "";
    const url =
      websiteHost.startsWith("http://") || websiteHost.startsWith("https://")
        ? websiteHost
        : `http://${websiteHost}`;

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {websiteHost}
      </a>
    );
  };
  return (
    <div>
      {loading && <Loader />}
      <p className="h2">My Website List</p>
      <div className="m-2 d-flex gap-2 justify-content-between align-items-center  w-100 ">
      <div className="pr-3">
          <span className="font-bold ">Total Records:</span>
          <span> {totalRecords} </span>
        </div>
        <div
          
          className="d-flex  flex-row align-items-center  "
        >

          <div className="d-flex gap-2 cursor-pointer">
            <div className="field mx-3">
              <label htmlFor="description">Mapping Status </label>
              <Dropdown
                value={selectedMappingStatus}
                onChange={(e) => setSelectedMappingStatus(e.value)}
                options={mappingType}
                optionLabel="name"
                placeholder="Select mapping type"
                // className="w-full f-14 md:w-14"
                className="w-full md:w-14rem md:h-3rem"
              />
            </div>
          </div>
          <div className="d-flex gap-2 cursor-pointer">
            <div className="field mx-3">
              <label htmlFor="description">Website Type </label>
              <Dropdown
                value={selectWebsiteType}
                onChange={(e) => setSelectWebsiteType(e.value)}
                options={websiteTypeItems}
                optionLabel="name"
                placeholder="Select website type"
                className="w-full md:w-14rem md:h-3rem"
              />
            </div>
          </div>
          <div className="d-flex gap-2 mt-3">
            <span className="p-input-icon-left d-flex gap-2">
              <i className="pi pi-search" />
              <InputText
                type="search website"
                onInput={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
                className="p-inputtext-sm md:h-3rem"
              />

              <Button
                variant="outline-success"
                disabled={loading}
                onClick={hanldeWebisteSearch}
                className="md:w-10rem"
              >
                Search
              </Button>
            </span>
          </div>
        </div>
        
      </div>

      <DataTable value={websiteListData} showGridlines>
        <Column body={renderWebsiteDetails} header="Website Name" />
        <Column body={renderWebsiteHost} header="URL" />
        <Column field="webapp_path" header="Website Path" />
        <Column field="section" header="Section" />
        <Column body={renderProgressbar} header="Mapped %" />
        <Column body={renderActions} header="Details" />
      </DataTable>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        onPageChange={onPageChange}
      />

      {/* <ConfirmDialog
        visible={confirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        message="Do you want to delete this record?"
        header="Delete Confirmation"
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        accept={acceptDelete}
        reject={() => setConfirmDialogVisible(false)}
      /> */}

      <Dialog visible={""} header="Detail" style={{ width: "50vw" }}>
        <div>
          <ul className="list-unstyled">
            <li className="text-dark h5">
              Title: <span className="h6"></span>
            </li>
            <li className="text-dark h5">
              URL Pattern: <span className="h6"></span>
            </li>
            <li className="text-dark h5">
              Description: <span className="h6"></span>
            </li>
          </ul>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Content</th>
              </tr>
            </thead>
            <tbody>
              {/* {hoveredMetatags.map((tag, index) => (
                <tr key={index}>
                  <td>{tag.name}</td>
                  <td>{tag.value}</td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  );
};

export default WebsiteList;
