import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import request from '../../request/request';
import { getAxios } from '../../axios/Axios';
import { useDebounce } from '../../utils/helper';

const UpdateProductForm = ({ data, dropDownData, handleUpdateProduct }) => {
	const navigate = useNavigate();

	const producTypeDropDown = dropDownData?.product_type?.map((item) => ({
		value: item.product_type_id,
		label: `${item.description}`,
	}));

	const productCategoryDrpdown = dropDownData?.category_list?.map((item) => ({
		value: item.product_category_id,
		label: `${item.category_name}`,
	}));

	const loadOptions = useDebounce(async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(
				`${request.productPriceUOM}?%24limit=500&%24offset=0&%24match=all&uom_type_id=CURRENCY_MEASURE&term=${inputValue}`
			);
			const options = response.data.items.map((item) => ({
				label: `${item?.description}`,
				value: item?.uom_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	}, 1000);

	const poductTermUOM = useDebounce(async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.termUOM}?${inputValue}`);
			const options = response.data.items.map((item) => ({
				label: `${item?.description}`,
				value: item?.uom_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	}, 1000);

	return (
		<div className=" card update-category-form d-flex align-items-center align-self-start">
			<div className=" w-100 d-flex justify-content-center p-4 m-auto">
				<div className="card-body">
					<form onSubmit={handleUpdateProduct}>
						<div className=" mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Name
							</label>

							<input
								type="text"
								className="form-control"
								placeholder="Product Name"
								name="product_name"
								defaultValue={data?.product_name || ''}
							/>
						</div>
						<div className=" mb-3">
							<label htmlFor="" className="form-label">
								Description
							</label>
							<textarea
								type="text"
								className="form-control"
								name="product_description"
								placeholder="Description"
								defaultValue={data?.product_description || ''}
							/>
						</div>

						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Type
							</label>

							<Select
								options={producTypeDropDown}
								className="basic-multi-select"
								classNamePrefix="select"
								placeholder="Select Product Type"
								name="product_type_id"
								// onChange={(selectedOption) =>
								// 	setPositionType(selectedOption.value)
								// }
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product ID
							</label>

							<input
								type="text"
								className="form-control"
								placeholder="Product Name"
								name="product_id"
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Price
							</label>

							<input
								type="text"
								className="form-control"
								placeholder="Product Name"
								name="product_price"
								defaultValue={data?.product_price || ''}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Price UOM
							</label>

							<AsyncSelect
								cacheOptions
								loadOptions={loadOptions}
								defaultOptions
								name="product_price_uom"
								// onChange={setSelectedParentCategory}
								// value={selectedParentCategory}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Code
							</label>

							<input
								type="text"
								className="form-control"
								placeholder="Product Name"
								name="product_code"
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Term UOM
							</label>

							<AsyncSelect
								cacheOptions
								loadOptions={poductTermUOM}
								defaultOptions
								name="product_term_uom"
								// onChange={setSelectedParentCategory}
								// value={selectedParentCategory}
							/>
						</div>

						<div className="form-group mb-3">
							<label htmlFor="" className="form-label form-required">
								Product Category
							</label>

							<Select
								options={productCategoryDrpdown}
								className="basic-multi-select"
								classNamePrefix="select"
								placeholder="Select position"
								name="category_ids"
								
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="formFile" className="">
								Upload Product Photo
							</label>

							<span
								style={{
									color: '#999',
									fontSize: '12px',
								}}
							>
								{' '}
								(Only Svg files supported with icon size of 512px/512px)
							</span>
							<br></br>

							<span
								style={{
									color: 'red',
									marginBottom: '5px',
								}}
								className="icon-delete"
							>
								Kindly delete previous category icon before uploading new one
							</span>

							<input
								className={`form-control`}
								type="file"
								id="formFile"
								name="product_photo"
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="formFile" className="">
								Additional Photo
							</label>

							<span
								style={{
									color: '#999',
									fontSize: '12px',
								}}
							>
								{' '}
								(Only Svg files supported with icon size of 512px/512px)
							</span>
							<br></br>

							<span
								style={{ color: 'red', marginBottom: '5px' }}
								className="icon-delete"
							>
								Kindly delete previous category icon before uploading new one
							</span>

							{/* )} */}
							<input className={`form-control`} type="file" id="formFile" />
						</div>

						<div className="d-flex prime-react-btn w-100 align-items-center gap-2 ">
							<button
								className="btn w-100 text-white"
								style={{ backgroundColor: 'grey' }}
								onClick={() => {
									navigate('/global-product-list');
								}}
							>
								Go Back
							</button>
							<button type="submit" className="btn submit-btn w-100 text-white">
								Update
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* <>
				<ImageCropper
					setCrop={setCrop}
					crop={crop}
					src={src}
					setCompletedCrop={setCompletedCrop}
					zoom={zoom}
					setZoom={setZoom}
					bannerType={bannerType}
					generateCroppedImage={generateCroppedImage}
					handleCloseCropper={handleCloseCropper}
				/>
			</> */}
		</div>
	);
};

export default UpdateProductForm;
